<template>
  <v-menu v-model="model" open-on-hover offset-y offset-x :position-x="positionX" :position-y="positionY">
    <v-card
      v-if="isLoading"
      style="padding: 20px"
      @mouseenter.native="mouseEnterHandler"
      @mouseleave.native="mouseLeaveHandler"
    >
      <v-progress-circular indeterminate color="primary" size="50" />
    </v-card>

    <v-list
      v-else
      dense
      two-line
      subheader
      class="context-menu"
      max-width="50vw"
      @mouseenter.native="mouseEnterHandler"
      @mouseleave.native="mouseLeaveHandler"
    >
      <template v-for="(section, index) in sections">
        <v-subheader :key="index">{{ section.header }}</v-subheader>
        <template v-for="(item, i) in section.items">
          <v-list-item
            v-if="'href' in item"
            :key="`${index}-${i}`"
            :href="item.href"
            :target="item.href ? '_blank' : undefined"
            class="list-item"
          >
            <v-list-item-content>
              <v-list-item-title>{{ item.label }}</v-list-item-title>
              <v-list-item-subtitle class="sub-label">{{ item.subLabel }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="'to' in item" :key="`${index}-${i}`" :to="item.to" class="list-item">
            <v-list-item-content>
              <v-list-item-title>{{ item.label }}</v-list-item-title>
              <v-list-item-subtitle class="sub-label">{{ item.subLabel }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>
      </template>
      <v-list-item v-if="query" class="query">
        <v-list-item-content class="query-content">{{ query }}</v-list-item-content>
        <v-list-item-icon class="copy-icon">
          <v-btn icon small @click="clickHandler">
            <v-icon small v-text="'mdi-content-copy'"></v-icon>
          </v-btn>
        </v-list-item-icon>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import { ContextMenuItem } from './types';

@Component
export default class ContextMenu extends Vue {
  @Prop()
  value!: boolean;

  @Prop()
  query?: string;

  @Prop()
  positionX!: number;

  @Prop()
  positionY!: number;

  @Prop()
  isLoading!: boolean;

  @Prop()
  sections!: { header: string; items: ContextMenuItem[] }[];

  over = false;

  get model() {
    return this.over || this.value;
  }
  set model(value) {
    this.$emit('input', value);
  }

  mouseEnterHandler() {
    this.over = true;
  }
  mouseLeaveHandler() {
    this.over = false;
  }
  async clickHandler() {
    // v-if で query がある時だけしか呼ばれないため non-null-assertion をしても問題ない
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    await navigator.clipboard.writeText(this.query!);
    this.$toast.open({
      message: `${this.query} をクリップボードにコピーしました`,
      type: 'info',
      duration: 1000,
      dismissible: true,
    });
  }
}
</script>

<style lang="scss" scoped>
.context-menu {
  &:has(.query) {
    padding-bottom: 0;
  }

  .sub-label {
    color: #999 !important;
  }
  .list-item {
    min-height: 50px;
  }
  .query {
    font-size: 12px;
    font-weight: bold;
    background: #f5f5f5;
    min-height: 0;
    padding: 0 8px;

    .query-content {
      color: #999 !important;
    }
  }
  .copy-icon {
    margin: 6px 0;
    align-self: center;
  }
}
</style>
