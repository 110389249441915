<template>
  <div class="document-findbar">
    <v-text-field
      ref="inDocumentSearchBox"
      v-model="lastKeyword"
      autofocus
      solo
      hide-details
      :loading="searching"
      class="in-document-search-box"
      placeholder="複数ワードで検索できます"
      tabindex="2"
      title="Esc キーで隠す (Ctrl + F で再表示)"
      @blur="updateKeywords()"
      @input="inputHandler($event)"
      @keypress.enter="searchSubmitHandler($event)"
      @keydown.backspace="popLastKeywordHandler($event)"
    >
      <template slot="prepend-inner">
        <v-icon>mdi-magnify</v-icon>
        <div class="tag-container">
          <keyword-tag
            v-for="(word, index) in fixedKeywords"
            :key="`${index}-${word}`"
            ref="keyword-tag"
            :word="word"
            @remove="removeKeyword(`${index}-${word}`)"
          />
        </div>
      </template>
      <template slot="append">
        <v-btn
          ref="jumpHitPreviousBtn"
          class="button-prev"
          icon
          title="文書内の前のヒット箇所にジャンプ (Shift + Enter)"
          :disabled="!(totalHit > 0)"
          @click="$emit('jumpPrev')"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <div class="hit-count" :class="{ '-found': hasHit }">
          {{ countLabel }}
        </div>
        <v-btn
          ref="jumpHitNextBtn"
          class="button-next"
          icon
          title="文書内の次のヒット箇所にジャンプ (Enter)"
          :disabled="!hasHit"
          @click="$emit('jumpNext')"
        >
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
        <v-btn
          icon
          class="button-close"
          color="primary"
          title="文書内検索ボックスを隠す"
          @click="$emit('hideSearchBox', $event)"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-text-field>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, State, Watch } from 'nuxt-property-decorator';
import { arraysEqual, invokeRippleEffect } from '@/utility';
// FIXME: `throttle-debounce` に変更する
import { debounce } from 'debounce';
import { State as MyState } from '@/store';
import KeywordTag from './keyword-tag.vue';
import { splitKeyword } from '@/utils/searchUtils';

@Component({ components: { KeywordTag } })
export default class DocumentFindbar extends Vue {
  $refs!: {
    inDocumentSearchBox: HTMLTextAreaElement;
    jumpHitPreviousBtn: Vue;
    jumpHitNextBtn: Vue;
    'keyword-tag': Vue[];
    [key: string]: Vue | Element | Vue[] | Element[];
  };

  @Prop() keyword!: string;

  @Prop() searching!: boolean;

  @Prop() totalHit!: number;

  @Prop() currentHit!: number;

  /** ハイライトすべきキーワード */
  @State((state: MyState) => state.document.find.keywords) keywords!: string[];

  get hasHit(): boolean {
    return this.totalHit > 0;
  }

  get countLabel(): string {
    if (this.totalHit === null) return '--';
    if (this.hasHit) return `${(this.currentHit + 1).toLocaleString()} / ${this.totalHit.toLocaleString()}`;
    return `-- / ${this.totalHit.toLocaleString()}`;
  }

  /** 確定済みのキーワード（最後のキーワード以外） */
  fixedKeywords: string[] = [];

  /** 最後のキーワード（インプットに適用されて編集できるキーワード） */
  lastKeyword = '';

  async scrollToLastFixedKeyword(): Promise<void> {
    await this.$nextTick();
    if (this.$refs['keyword-tag'] === undefined) return;
    if (this.$refs['keyword-tag'].length < 1) return;
    const el = this.$refs['keyword-tag'][this.$refs['keyword-tag'].length - 1].$el;
    el.scrollIntoView();
  }

  /** 値が変更された時に呼ばれて入力ボックスに2ワード以上が存在したら分割して最後のワード以外を fixedKeywords に移動する */
  inputHandler(inputKeyword: string): void {
    const { keywords, isLastBlank } = splitKeyword(inputKeyword);
    // すぐに実行するとペースト処理の前に値が更新されてしまいモデルと見た目に不整合が出るので遅延実行する
    this.$nextTick(() => {
      if (isLastBlank) {
        // 末尾に空白がある場合はすべてを fixed に移動して、入力欄は空白にする
        this.lastKeyword = '';
        this.fixedKeywords = [...this.fixedKeywords, ...keywords];
        this.scrollToLastFixedKeyword();
      } else if (keywords.length > 1) {
        // そうじゃない場合は、末尾のキーワード以外を fixed に移動する
        this.lastKeyword = keywords.pop() || '';
        this.fixedKeywords = [...this.fixedKeywords, ...keywords];
        this.scrollToLastFixedKeyword();
      }
    });
  }

  /** BackSpace が押された時に呼ばれる。入力エリアがからだった場合に fixedKeywords の最後を入力ボックスに pop する */
  popLastKeywordHandler(e: KeyboardEvent): void {
    if (this.lastKeyword === '') {
      this.lastKeyword = this.fixedKeywords.pop() || '';
      this.scrollToLastFixedKeyword();
      e.preventDefault();
    }
  }

  /** エンターが押されると呼ばれる。入力中の文字を確定して検索を実行する */
  searchSubmitHandler(e: KeyboardEvent): void {
    this.updateKeywords();
    this.$nextTick(() => {
      this.onBoxSearchEnter(this.keyword, e);
    });
  }

  updateKeywords(): void {
    const { keywords } = splitKeyword(this.lastKeyword);
    this.fixedKeywords = [...this.fixedKeywords, ...keywords];
    this.lastKeyword = '';
    this.scrollToLastFixedKeyword();
    this.$emit('update:keyword', this.fixedKeywords.join(' '));
  }

  removeKeyword(key2remove: string): void {
    this.fixedKeywords = this.fixedKeywords.filter((word, index) => `${index}-${word}` !== key2remove);
  }

  focus(): void {
    this.$refs.inDocumentSearchBox.focus();
  }

  @Watch('keyword', { immediate: true })
  keywordUpdateHandler(): void {
    this.lastKeyword = '';
    this.fixedKeywords = this.keyword
      .replace(/\s+/, ' ')
      .split(' ')
      .filter((word) => word.length > 0);
  }

  /** 文書内検索ボックスでEnterキーを押したとき */
  async onBoxSearchEnter(keyword: string, event: KeyboardEvent): Promise<void> {
    await this.$nextTick();

    const keywords = keyword.split(/\s+/).filter((keyword, i, all) => keyword && all.indexOf(keyword) === i);

    if (arraysEqual(keywords, this.keywords)) {
      // 前へ/次へ
      if (event.shiftKey) {
        this.invokeClickEffectOnJumpHitPreviousBtn();
        this.$emit('jumpPrev');
      } else {
        this.invokeClickEffectOnJumpHitNextBtn();
        this.$emit('jumpNext');
      }
    } else {
      // 本来は ToC の sectionHit を null に更新しなければならないが、 keyword が変わることで API リクエストが走って再設定されるのでここでは更新していない
      this.$store.commit('requestUpdateHighlights', keywords);
    }
  }

  /** 前へボタンの波紋エフェクトを表示する */
  private invokeClickEffectOnJumpHitPreviousBtn = debounce(
    () => invokeRippleEffect(this.$refs.jumpHitPreviousBtn.$el as HTMLElement),
    100,
    true,
  );

  /** 次へボタンの波紋エフェクトを表示する */
  private invokeClickEffectOnJumpHitNextBtn = debounce(
    () => invokeRippleEffect(this.$refs.jumpHitNextBtn.$el as HTMLElement),
    100,
    true,
  );
}
</script>

<style lang="scss" scoped>
.document-findbar {
  position: absolute;
  width: 100%;
  min-width: 300px;
  max-width: 486px;
  left: 14px;
  padding-right: 14px;

  ::v-deep {
    .v-text-field input {
      font-size: 13px;
    }

    .v-input__control {
      align-items: center;

      .v-input__append-inner {
        align-items: center;
      }
    }
    .v-text-field.v-text-field--solo .v-input__control {
      min-height: 42px;
    }
    .primary--text {
      color: rgba(0, 0, 0, 0.54) !important;
    }
  }
}

.in-document-search-box {
  .hit-count {
    font-size: 12px;
    color: #b5b5b5;
    white-space: nowrap;
    padding: 0 7px 0 5px;

    &.-found {
      color: #383333;
    }
  }
}
.button-prev,
.button-next,
.button-close {
  ::v-deep {
    .v-btn--icon.v-size--default {
      width: 24px !important;
      height: 24px !important;
      margin: 0 2px 0 3px;
    }

    .v-icon {
      color: $primary !important;
    }
  }
}
.tag-container {
  padding-left: 4px;
  display: flex;
  flex-wrap: nowrap;
  max-width: 200px;
  overflow-y: hidden;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}
</style>
