import { DocRecord, DocumentTypeEnum } from 'wklr-backend-sdk/models';

export const fragmentBibliography = (record: DocRecord): string => {
  if (record) {
    const quotes = record.type === DocumentTypeEnum.Book ? '『』' : '「」';
    return `${record.authors.join(',')}${quotes[0]}${record.title}${quotes[1]}（${record.publisher}、${new Date(
      record.publishedOn,
    ).getFullYear()}）`;
  } else return '書誌情報を取得できませんでした。';
};

export const fragmentUrl = (record: DocRecord, key: string, series?: string): string => {
  return series === undefined
    ? `${location.origin}/document/${record.id}#key=${key}`
    : `${location.origin}/document/${record.id}#key=${key}&series=${series}`;
};
