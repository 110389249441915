<template>
  <div id="kommentar-article" class="kommentar-article" :class="classes" :title="title" @click="$emit('toggle')">
    <template v-if="isOpen">
      <div class="fullArticle scrollable" :class="`font-size-${fontSize}`" :style="{ height: `${height - 4 * 2}px` }">
        <dl class="detail">
          <dt class="label">解説中の条文</dt>
          <dd class="content">
            <!-- eslint-disable-next-line vue/no-v-html -->
            <p class="article" v-html="articleContent" />
          </dd>
        </dl>
        <section class="meta">
          <dl class="detail">
            <dt class="label">コンメンタール著者</dt>
            <dd class="content">
              {{ authors }}
            </dd>
          </dl>
          <dl class="detail">
            <dt class="label">更新日</dt>
            <dd class="content">
              {{ updatedOn }}
            </dd>
          </dl>
        </section>
      </div>
      <v-btn icon class="button" aria-expanded="true" @click.stop="$emit('toggle')">
        <v-icon>mdi-unfold-less-horizontal</v-icon>
      </v-btn>
    </template>
    <template v-else>
      <p class="snippet">
        {{ kommentarArticle.text }}
      </p>
      <v-btn icon class="button" aria-expanded="false" aria-controls="kommentar-article" @click.stop="$emit('toggle')">
        <v-icon>mdi-unfold-more-horizontal</v-icon>
      </v-btn>
    </template>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, State } from 'nuxt-property-decorator';
import { State as MyState } from '@/store';
import { DocumentConfig } from '@/types/document-config';
import { KommentarArticle } from 'wklr-backend-sdk/models';
import { formatYmd } from '@/utility';

@Component
export default class KommentarArticleBar extends Vue {
  @Prop() kommentarArticle!: KommentarArticle;
  @Prop() isOpen!: boolean;
  @Prop() height!: number;

  /** 閲覧画面の設定 */
  @State((state: MyState) => state.persistent.documentConfig.bodyFontSize) fontSize!: DocumentConfig['bodyFontSize'];

  get articleContent(): string {
    return this.kommentarArticle.text.split(/\r\n|\n|\r/).join('<br />');
  }

  get authors(): string {
    return this.kommentarArticle.authors.join('/');
  }

  get updatedOn(): string {
    return formatYmd(this.kommentarArticle.updatedOn);
  }

  get classes(): string[] {
    const classes = [`font-size-${this.fontSize}`];
    if (!this.isOpen) classes.push('-close');
    return classes;
  }

  get title(): string {
    return this.isOpen
      ? 'セクションをクリックするとサイズが小さくなります'
      : 'セクションをクリックすると解説中の条文の全体とコンメンタール著者、解説の更新日が表示されます';
  }
}
</script>

<style lang="scss" scoped>
@import '@/../node_modules/vuetify/src/styles/styles.sass';
@import '@/../node_modules/vuetify/src/styles/generic/_elevation.scss';

.kommentar-article {
  @extend .elevation-1;
  position: absolute;
  left: 14px;
  width: 85%;
  padding: 4px 0;
  background: white;
  border-radius: 5px;
  color: black;
  cursor: pointer;

  > .button {
    position: absolute;
    top: auto;
    bottom: 10px;
    right: 16px;
  }

  > .fullArticle {
    overflow-y: auto;
    padding: 8px 16px;

    &.font-size-14 {
      font-size: 14px;
    }
    &.font-size-18 {
      font-size: 18px;
    }
    &.font-size-24 {
      font-size: 24px;
    }
    &.font-size-34 {
      font-size: 34px;
    }

    > .meta > .detail,
    > .detail {
      > .label {
        padding: 0 0 2px;
        font-weight: bold;
        font-size: 0.8em;
      }
      > .content {
        padding: 0 0 8px 15px;

        &,
        > .article {
          font-family: 'Noto Serif JP', serif;
        }
      }
    }

    > .meta {
      display: flex;

      > .detail {
        flex-grow: 1;

        > .content {
          padding-bottom: 0;
        }
      }
    }
  }

  &.-close {
    > .snippet {
      margin: 0;
      padding: 8px 52px 8px 16px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-family: 'Noto Serif JP', serif;
    }

    > .button {
      top: 6px;
      bottom: auto;
    }

    &.font-size-14 {
      font-size: 14px;
    }
    &.font-size-18 {
      font-size: 18px;
    }
    &.font-size-24 {
      font-size: 24px;
    }
    &.font-size-34 {
      font-size: 34px;
    }
  }
}
</style>
