<template>
  <section>
    <p>読み込みに失敗しました<br /><span v-if="hrefDocument">書籍閲覧画面にアクセスしてください</span></p>
    <viewer-link v-if="hrefDocument" :href-document="hrefDocument">進む</viewer-link>
  </section>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'nuxt-property-decorator';
import ViewerLink from '@/components/viewer-link.vue';
import { DocRecord } from 'wklr-backend-sdk/models';

@Component({ components: { ViewerLink } })
export default class LoadingError extends Vue {
  @Prop()
  hrefDocument!: DocRecord | string;
}
</script>
