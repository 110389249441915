import { sectionKeysWithAncestorsAndDescendants } from '@/utils/webViewerUtils';
import { generateURLFromString } from '@/utils/urlUtils';
import { hrefType } from '@/utils/routeUtils';
import { DocRecord } from 'wklr-backend-sdk/models';
import { getKeyFromSeq } from '@/utility';
import { ContextMenuItem } from '@/components/shared/context-menu';

type LinkInternalDocument = {
  type: 'link-internal-document';
  href: URL;
  docId: string;
};

type LinkInternalOther = {
  type: 'link-internal-other';
  href: URL;
};

type LinkExternal = {
  type: 'link-external';
  href: URL;
};

type LinkSamePath = {
  type: 'link-same-path';
  key: number;
};

type DelegationReferenceLaw = {
  type: 'delegation-reference';
  delegationType: 'law';
  delegationDocId: string;
  delegationQuery: string;
  sectionNumber: string;
  sectionKeys: number[];
};

type ExternalReference = {
  type: 'external-reference';
  referenceType: 'private' | 'public' | 'unknown';
  searchQuery: string;
  alternativeLinks: Record<string, URL>;
};

type UnavailableReference = {
  type: 'unavailable-reference';
  searchQuery: string;
};

const LINK_INVALID = {
  type: 'link-invalid',
} as const;

type LinkInvalid = typeof LINK_INVALID;

type LinkType =
  | LinkInternalDocument
  | LinkInternalOther
  | LinkExternal
  | LinkSamePath
  | DelegationReferenceLaw
  | ExternalReference
  | UnavailableReference
  | LinkInvalid;

export const searchServices = {
  ndlonline: {
    label: '国立国会図書館',
    subLabel: 'ndlonline.ndl.go.jp',
    href: (query: string) =>
      `https://ndlonline.ndl.go.jp/#!/search?searchCode=SIMPLE&lang=jp&keyword=${encodeURIComponent(query)}`,
  },
  google: {
    label: 'Google',
    subLabel: 'www.google.com',
    href: (query: string) => `https://www.google.com/search?q=${encodeURIComponent(query)}`,
  },
  googleBooks: {
    label: 'Google Books',
    subLabel: 'www.google.com',
    href: (query: string) => `https://www.google.com/search?tbm=bks&q=${encodeURIComponent(query)}`,
  },
  amazon: {
    label: 'Amazon.co.jp',
    subLabel: 'www.amazon.co.jp',
    href: (query: string) => `https://www.amazon.co.jp/s?i=stripbooks&k=${encodeURIComponent(query)}`,
  },
  shiseido: {
    label: '至誠堂書店',
    subLabel: 'https://ssl.shiseido-shoten.co.jp',
    href: (query: string) =>
      `https://ssl.shiseido-shoten.co.jp/products/list?name=${encodeURIComponent(query)}&pmin=&pmax=`,
  },
};

const getAlternativeLinks = (
  href: URL,
  label?: string,
  alternativeLinks?: [string, string | undefined][],
): Record<string, URL> => {
  {
    // FALLBACK
    /* eslint-disable @typescript-eslint/no-non-null-assertion */
    if (/database\.shojihomu\.co\.jp\/nbl\/app\/external\/doc/.test([href.host, href.pathname].join(''))) {
      return Object.fromEntries([
        [
          label || generateSaaSSiteLabel('database.shojihomu.co.jp')!,
          new URL(`https://database.shojihomu.co.jp/nbl/app/external/doc${href.search}${href.hash}`),
        ],
        [
          generateSaaSSiteLabel('go.westlawjapan.com')!,
          new URL(`https://go.westlawjapan.com/wljp/app/external/doc${href.search}${href.hash}`),
        ],
      ]);
    }
    if (/database\.shojihomu\.or\.jp\/jksh\/app\/external\/doc/.test([href.host, href.pathname].join(''))) {
      return Object.fromEntries([
        [
          label || generateSaaSSiteLabel('database.shojihomu.co.jp')!,
          new URL(`https://database.shojihomu.or.jp/jksh/app/external/doc${href.search}${href.hash}`),
        ],
        [
          generateSaaSSiteLabel('go.westlawjapan.com')!,
          new URL(`https://go.westlawjapan.com/wljp/app/external/doc${href.search}${href.hash}`),
        ],
      ]);
    }
    /* eslint-enable @typescript-eslint/no-non-null-assertion */
  }

  const hrefItem: [string, URL][] = [[label || generateSaaSSiteLabel(href.host) || href.host, href]];
  if (alternativeLinks !== undefined) {
    const primaryItems: [string, URL][] = alternativeLinks.map(([href, label]) => {
      const url = new URL(href);
      return [label || generateSaaSSiteLabel(url.host) || url.host, url];
    });
    return Object.fromEntries([...hrefItem, ...primaryItems]);
  }
  return Object.fromEntries(hrefItem);
};

export const getLinkType = (target: HTMLAnchorElement, record: DocRecord): LinkType => {
  const href = generateURLFromString(target.href);

  if (target.classList.contains('delegation-reference')) {
    const { delegationType, delegationDocId, delegationKey, delegationQuery } = target.dataset;
    if (
      delegationType === undefined ||
      delegationDocId === undefined ||
      delegationKey === undefined ||
      delegationQuery === undefined
    ) {
      console.error('required dataset for "delegation-reference" link ara not set');
      return LINK_INVALID;
    }
    switch (delegationType) {
      case 'law': {
        const sectionKey = Number.parseInt(delegationKey);
        let p: Element | null = target;
        while (p !== null && p.tagName != 'P') {
          p = p.parentElement;
        }
        const sectionNumber = p ? decodeURIComponent(p.getAttribute('name') || '') : '';
        const sectionKeys = sectionKeysWithAncestorsAndDescendants(record.toc, sectionKey);

        return {
          type: 'delegation-reference',
          delegationType: 'law',
          delegationDocId,
          delegationQuery,
          sectionNumber,
          sectionKeys,
        };
      }
    }
    console.error(`unknown delegation type "${delegationType}" is set to delegation-reference`);
  }

  // fallback: data-search-query が存在しない場合は対象要素の innerText を検索クエリとみなす
  const searchQuery = target.dataset.searchQuery || target.innerText;

  if (target.classList.contains('external-reference')) {
    const { referenceType, referenceLabel } = target.dataset;
    if (referenceType === undefined) {
      console.error('no reference type is specified to external-reference');
      return LINK_INVALID;
    }
    switch (referenceType) {
      case 'public':
      case 'private': {
        if (href === null) {
          console.error('href for external-reference is not set');
          return LINK_INVALID;
        }
        const {
          alternativeReferenceUrl: _alternativeReferenceUrl,
          alternativeReferenceLabel: _alternativeReferenceLabel,
        } = target.dataset;
        const alternativeReferenceUrl = _alternativeReferenceUrl?.split(',') || [];
        const alternativeReferenceLabel = _alternativeReferenceLabel?.split(',') || [];
        const alternativeLinks: [string, string | undefined][] = alternativeReferenceUrl.map((url, index) => [
          url,
          alternativeReferenceLabel[index],
        ]);
        if (searchQuery) {
          return {
            type: 'external-reference',
            referenceType,
            searchQuery,
            alternativeLinks: getAlternativeLinks(href, referenceLabel, alternativeLinks),
          };
        }
        break;
      }
      case 'unknown': {
        if (searchQuery) {
          return {
            type: 'unavailable-reference',
            searchQuery,
          };
        }
      }
    }
    console.error(`unknown reference type "${referenceType}" is set to external-reference`);
  }

  if (target.classList.contains('unavailable-reference')) {
    return {
      type: 'unavailable-reference',
      searchQuery,
    };
  }

  // fallback: ハッシュでも unavailable を取得できるようにする
  if (href?.hash === '#unavailable') {
    return {
      type: 'unavailable-reference',
      searchQuery,
    };
  }

  {
    // fallback のためにオーバーライドする
    const { searchQuery } = target.dataset;
    if (searchQuery) {
      // fallback: data-search-query があり href も指定されている場合は、外部検索サービスとみなす
      if (href) {
        return {
          type: 'external-reference',
          referenceType: 'unknown',
          searchQuery,
          alternativeLinks: getAlternativeLinks(href),
        };
      } else {
        // fallback: data-search-query があり href が指定されていない場合は、非掲載文献とみなす
        return {
          type: 'unavailable-reference',
          searchQuery,
        };
      }
    }
  }

  if (href === null) {
    return LINK_INVALID;
  }

  // fallback: external-reference が指定されていなくても、 特定のホスト名の場合は、外部検索サービスとみなす
  {
    const query = target.innerText;
    if (generateSaaSSiteLabel(href.host)) {
      return {
        type: 'external-reference',
        referenceType: 'unknown',
        searchQuery: query,
        alternativeLinks: getAlternativeLinks(href),
      };
    }
  }

  const targetHrefType = hrefType(href);

  switch (targetHrefType) {
    case 'internal': {
      const match = href.pathname.match(/^\/document\/([^/?#]+)/);
      if (match) {
        return {
          type: 'link-internal-document',
          href,
          docId: match[1],
        };
      }
      return {
        type: 'link-internal-other',
        href,
      };
    }
    case 'same-path':
      {
        const match = href.hash.match(/key=(\d+)/);

        if (match) {
          return {
            type: 'link-same-path',
            // key が存在する場合は web-viewer で 0-indexed の値が入っているのでそのまま返す
            key: Number.parseInt(match[1], 10),
          };
        }
      }
      {
        const match = href.hash.match(/page=(\d+)/);

        if (match) {
          // page が存在する場合は pdf-viewer で 1-indexed の値が入っているので -1 をしてから返す
          const key = getKeyFromSeq(record.toc, Number.parseInt(match[1], 10) - 1);

          if (key !== void 0) {
            return {
              type: 'link-same-path',
              // key が存在する場合は web-viewer で 0-indexed の値が入っているのでそのまま返す
              key: Number.parseInt(match[1], 10),
            };
          }
        }
      }
      console.error(`fail to get page number from record`);
      return LINK_INVALID;
    case 'external':
      return {
        type: 'link-external',
        href,
      };
  }
};

/** 参照リンクの対象となるドメインマッチャとラベル文字列の配列 */
export const EXTERNAL_LINK_SITE_LABEL: { matcher: RegExp; label: string }[] = [
  {
    matcher: /(.+\.|)courts\.go\.jp/,
    label: '裁判所ウェブサイト',
  },
  {
    matcher: /(.+\.|)kfs\.go\.jp/,
    label: '国税不服審判所ホームページ',
  },
  {
    matcher: /(.+\.|)ndlonline\.ndl\.go\.jp/,
    label: '国立国会図書館オンライン',
  },
  {
    matcher: /(.+\.|^)asb\.or\.jp/,
    label: '企業会計基準委員会（ASBJ）',
  },
];

/** 特殊参照リンクの対象となるドメインマッチャとラベル文字列のオブジェクトを取得する */
export const generateSaaSSiteLabel = (host: string): string | undefined =>
  // 最後の要素がすべてにマッチするので undefined が返ることはない
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  [
    {
      matcher: /(.+\.|)database\.shojihomu\.or\.jp/,
      siteLabel: '商事法務研究会「旬刊商事法務データベース」',
    },
    {
      matcher: /(.+\.|)database\.shojihomu\.co\.jp/,
      siteLabel: '商事法務「商事法務データベース」',
    },
    {
      matcher: /(.+\.|)bun-dh.d1-law\.com/,
      siteLabel: '第一法規「D1-Law.com 法律判例文献情報」',
    },
    {
      matcher: /(.+\.|)go\.westlawjapan\.com/,
      siteLabel: 'ウエストロー・ジャパン',
    },
    {
      matcher: /(.+\.|)courts\.go\.jp/,
      siteLabel: '裁判所ウェブサイト',
    },
    {
      matcher: /(.+\.|)kfs\.go\.jp/,
      siteLabel: '国税不服審判所ホームページ',
    },
    {
      matcher: /(.+\.|)ndlonline\.ndl\.go\.jp/,
      siteLabel: '国立国会図書館オンライン',
    },
    {
      matcher: /(.+\.|^)asb\.or\.jp/,
      siteLabel: '企業会計基準委員会（ASBJ）',
    },
  ].find(({ matcher }) => matcher.test(host))?.siteLabel;

/** リンク要素から参照リンク先のラベルに使う文字列を取得する */
export const getReferenceLabel = (target: HTMLAnchorElement) => {
  const { referenceLabel } = target.dataset;
  if (!referenceLabel) return null;
  return referenceLabel;
};

/** 特殊参照リンクのコンテキストメニューに表示する要素の配列を生成する */
export const generateItems = (
  searchQuery: string,
  alternativeLinks?: Record<string, URL>,
): { header: string; items: ContextMenuItem[] }[] => {
  console.log({ alternativeLinks });
  // const primaryLinkItems = alternativeLinks.map((url) => ({ ...generateSaaSSiteLinkItem(url.host), href: url.toString() }));
  const primaryLinkItems = Object.entries(alternativeLinks || {}).map(([siteName, url]) => ({
    label: siteName || generateSaaSSiteLabel(url.host) || url.host,
    subLabel: url.host,
    href: url.toString(),
  }));
  const searches = Object.values(searchServices).map(({ href, ...rest }) => ({ ...rest, href: href(searchQuery) }));

  if (primaryLinkItems.length > 0) {
    return [
      {
        header: 'クリックで外部サイトに移動します',
        items: primaryLinkItems,
      },
      {
        header: 'クリックで外部検索サービスに移動します',
        items: searches,
      },
    ];
  }
  return [
    {
      header: 'この文献はLegalscapeに収録されていません。以下の検索サービスより検索してください',
      items: searches,
    },
  ];
};
