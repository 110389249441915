<template>
  <div v-if="visible" class="wrapper elevation-24">
    <v-sheet color="blue" dark class="d-flex justify-center pa-3">
      <div>PDFの見開き設定を変更できます。設定は全書籍共通です。</div>
      <v-btn icon small class="ml-2" @click="closeNotice">
        <v-icon dark>mdi-close-circle</v-icon>
      </v-btn>
    </v-sheet>
  </div>
</template>

<script lang="ts">
import { Component, State, Vue } from 'nuxt-property-decorator';
@Component({})
export default class PdfSpreadModeNotice extends Vue {
  @State((state) => state.persistent.hasSeenPdfSpreadModeNotice2)
  hasSeenPdfSpreadModeNotice!: boolean;
  visible = false;

  mounted() {
    // 1500 ms 後に表示判定する
    // state が読み込まれるまで多少時間がかかるため
    setTimeout(() => this.updateVisibility(), 1500);
  }

  updateVisibility() {
    this.visible = !this.hasSeenPdfSpreadModeNotice;
  }

  closeNotice(): void {
    this.visible = false;
    this.$store.commit('setHasSeenPdfSpreadModeNotice', true);
  }
}
</script>

<style lang="scss" scoped>
.wrapper:after {
  content: '';
  position: absolute;
  right: 10px;
  bottom: 100%;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid #2196e3;
  z-index: 10;
}
</style>
