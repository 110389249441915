import VueRouter from 'vue-router';
import { Nullable } from '@/types/nullable';
import { isInView } from '@/utility';
import { DocRecord } from 'wklr-backend-sdk/models';
import { ContextMenuItem } from '@/components/shared/context-menu';
import { EXTERNAL_LINK_SITE_LABEL, generateItems, getReferenceLabel, getLinkType } from './linkType';
import { VisitSectionOptions } from '@/components/viewer/web-viewer';
import { Repositories } from '@/plugins/repositories';
import { loadCandidates } from './delegationLaw';
import { ToastApi } from 'vue-toast-notification';

/**
 *  TODO: めちゃくちゃワークアラウンドですが、これは 2022/12 現在 isBook として正しく動作します
 *
 * Big Query内参考
    SELECT * FROM `valued-clarity-241606.wklr_prod.doc` d WHERE
    d.type = 1 and not ((d.natural_id like "4%" and length(d.natural_id) = 10) or (d.natural_id like "9%"))
 */
const exceptionalBookNaturalIds = [
  'jpx.017094183',
  'courtsample',
  '2838338505',
  'jpx.reitguide',
  'jpx.017094175',
  'jpx.ifguide',
];
const isBook = (url: URL) => {
  const pathname = url.pathname;
  return (
    (pathname.startsWith('/document/9') && pathname.length == 23) ||
    (pathname.startsWith('/document/4') && pathname.length == 20) ||
    exceptionalBookNaturalIds.some((naturalId) => pathname.endsWith(naturalId))
  );
};

const findSidenoteBySidenoteMarkerText = (target: HTMLElement) => {
  // 直後ぐらいにある注マーカーが対応するものである可能性が高いという前提で探索している
  // 必要以上に探索するのを抑えるため上限を設定しておく
  const MAX_SIDENOTE_SEARCH_COUNT = 20;

  // TEMP: <sup>タグと注の紐づけが現状無いので sup の中身を含有する最初の項目を対象に処理を行う
  // 注マーカーが「１）」である一方、注釈が「１ ）」であるときに対応するため空白を削除する
  const targetNoSpaceText = target.innerText.normalize('NFKC');
  const sidenotes = Array.from(document.querySelectorAll<HTMLElement>('.sidenote')).slice(0, MAX_SIDENOTE_SEARCH_COUNT);
  if (sidenotes.length === 0) return;
  const [sidenote, _] = sidenotes.reduce<[HTMLElement | undefined, number]>(
    (acc, sidenote) => {
      const [_, candidatePos] = acc;
      if (!(target.compareDocumentPosition(sidenote) & Node.DOCUMENT_POSITION_FOLLOWING)) {
        return acc;
      }
      const current = (sidenote.textContent || '').normalize('NFKC').indexOf(targetNoSpaceText);
      if (current === -1) {
        return acc;
      }

      return current < candidatePos ? [sidenote, current] : acc;
    },
    [undefined, Number.MAX_SAFE_INTEGER],
  );
  return sidenote;
};

const findSidenote = (target: HTMLElement) => {
  const sidenoteMakerID = target.dataset['id'];
  const sidenote =
    document.querySelector(`[data-target-id="${sidenoteMakerID}"]`) ?? findSidenoteBySidenoteMarkerText(target);
  if (sidenote instanceof HTMLElement) return sidenote;
};

export const useContentMouseEvent = (
  repositories: Repositories,
  router: VueRouter,
  toast: ToastApi,
  setSidenotePopup: (value: string | null) => void,
  setAvailabilityPopupTargetDocumentId: (value: string | null) => void,
  visitSection: (key: number, series: Nullable<number>, option: VisitSectionOptions) => void,
  showContextMenu: (
    x: number,
    y: number,
    items: () =>
      | { header: string; items: ContextMenuItem[] }[]
      | Promise<{ header: string; items: ContextMenuItem[] }[]>,
    query: string,
    fallback?: (e: Error) => void,
  ) => void,
  showTooltip: (x: number, y: number, text: string) => void,
  hideContextMenu: () => void,
  hideTooltip: () => void,
  disableInternalBook: boolean,
) => {
  let isPreview = false;
  let record: DocRecord | null = null;

  const mouseout = (target: HTMLElement, event: MouseEvent): void => {
    // 注ポップアップを開いていれば閉じる
    setSidenotePopup(null);
    document.querySelectorAll('.sidenote.active').forEach((p) => p.classList.remove('active'));

    // コンテキストメニューも閉じる（それ自体にホバーしている場合コンテキストメニュー側で閉じることはないようになっている）
    hideContextMenu();

    // ツールチップを消す
    hideTooltip();

    // Legalscapeに収録されていますポップアップを開いていれば閉じる
    setAvailabilityPopupTargetDocumentId(null);
  };

  return {
    setIsPreview(value: boolean) {
      isPreview = value;
    },
    setRecord(value: DocRecord) {
      record = value;
    },
    click(target: HTMLElement, event: MouseEvent): void {
      // a タグ以外はとくに処理をしない
      if (!(target instanceof HTMLAnchorElement)) return;
      // プレビュー表示ではリンクはすべて無視する
      if (isPreview) return;
      // 発生しないはずだが record がなかった場合は何もしない
      if (!record) return;

      const link = getLinkType(target, record);

      mouseout(target, event);

      switch (link.type) {
        case 'external-reference': {
          const primaryLink = generateItems(link.searchQuery, link.alternativeLinks)[0]['items'][0];
          if ('href' in primaryLink) {
            // 型定義はキー href または to が含まれるオブジェクトが返るとしているが、generateItems は href しか返さないためここでは href のみを考慮する
            window.open(primaryLink.href, '_blank', 'noopener,noreferrer');
          }
          return;
        }
        case 'delegation-reference':
          loadCandidates(
            repositories,
            link.delegationDocId,
            link.sectionKeys,
            link.sectionNumber,
            link.delegationQuery,
          )()
            .then((candidates) => {
              // candidates が 0 の場合は reject されるのでここでは以下は必ず存在する
              const firstItem = candidates[0].items[0];
              if ('to' in firstItem) {
                router.push(firstItem.to);
              }
            })
            .catch(() => {
              // NOP
            });
          return;
        case 'link-internal-document':
        case 'link-internal-other':
          if (disableInternalBook && isBook(link.href)) {
            // do nothing
          } else {
            const query = new URLSearchParams(link.href.search);
            const q = new URLSearchParams(window.location.search).get('q');
            if (q) {
              query.append('q', q);
            }
            link.href.search = query.toString();
            window.open(link.href, '_blank', 'noopener,noreferrer');
          }
          return;
        case 'link-same-path':
          visitSection(link.key, null, { flash: true, pushHistory: true });
          return;
        case 'link-external':
          window.open(link.href.toString(), '_blank', 'noopener,noreferrer');
          return;
      }
    },
    mouseout,
    mouseover(target: HTMLElement, event: MouseEvent): void {
      // 発生しないはずだが record がなかった場合は何もしない
      if (!record) return;

      if (target.tagName === 'SUP') {
        const sidenote = findSidenote(target);

        if (!sidenote) return;
        if (isInView(sidenote)) {
          // 注自体が完全にin-view → 目立たせる
          sidenote.classList.add('active');
          return;
        }
        // 注自体が完全にはin-viewでない → ポップアップで表示
        setSidenotePopup(sidenote.innerHTML);
        return;
      }

      if (target instanceof HTMLAnchorElement) {
        // プレビュー表示では一旦リンクはすべて無視する
        if (isPreview) return;

        const link = getLinkType(target, record);

        const { clientX, clientY } = event;

        switch (link.type) {
          case 'external-reference':
            showContextMenu(
              clientX,
              clientY,
              () => generateItems(link.searchQuery, link.alternativeLinks),
              link.searchQuery,
            );
            return;
          case 'delegation-reference':
            showContextMenu(
              clientX,
              clientY,
              loadCandidates(
                repositories,
                link.delegationDocId,
                link.sectionKeys,
                link.sectionNumber,
                link.delegationQuery,
              ),
              '',
              (e) => toast.warning(e.message),
            );
            return;
          case 'unavailable-reference':
            showContextMenu(clientX, clientY, () => generateItems(link.searchQuery), link.searchQuery);
            return;
          case 'link-external':
            {
              const label =
                getReferenceLabel(target) ||
                EXTERNAL_LINK_SITE_LABEL.find(({ matcher }) => link.href.host.match(matcher))?.label ||
                link.href.host;
              showTooltip(clientX, clientY, `${label}にリンクします`);
            }
            return;
          case 'link-internal-document':
            if (disableInternalBook && isBook(link.href)) {
              showContextMenu(
                clientX,
                clientY,
                () => [{ header: 'この書籍は Legalscape に収録されています', items: [] }],
                target.text,
              );
            } else {
              // Legalscape上の別文書 → 収録されていますポップアップを表示（するタイマーを起動）する
              setAvailabilityPopupTargetDocumentId(link.docId);
            }
        }
      }
    },
  };
};
