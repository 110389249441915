/* eslint @typescript-eslint/no-explicit-any: 0 */
/* pdf.js とのインターフェイスの境界のため */

import { Component, Vue, Prop, Watch, State } from 'nuxt-property-decorator';
import { PDFPageIdentifier } from '@/types/pdf-page-identifier';
import { FindState } from '@/store';
import PdfSpreadModeNotice from '@/components/alert/pdf-spread-mode-notice.vue';
import LoadingError from '@/components/loading-error.vue';
import AddPageBookmarkDialog from '@/components/dialog/add-page-bookmark.vue';
import AddQuickAccessPdfItemDialog from '@/components/quickAccess/add-pdf-item.vue';
import PrintDialog from '@/components/dialog/print-dialog.vue';
import { mapByPageSeq } from '@/utils/pdfViewerUtils';
import * as constants from '@/constants';
import { EventBus } from 'pdfjs-dist/web/pdf_viewer';
import { OutlineItem } from '@/types/pdfjs';
import {
  BookmarkTypePdf,
  BookmarkViewTypeEnumPdf,
  DocRecord,
  DocumentTypeEnum,
  QuickAccessItem,
  QuickAccessItemTypePdf,
  QuickAccessItemTypePdfPropertiesViewTypeEnum,
} from 'wklr-backend-sdk/models';
import { isAccessible } from '@/utils/documentUtils';

const { viewerUrl } = constants.Document.PdfViewer;

interface CopyParams {
  text: string;
  successMessage: string;
  errorMessage: string;
}

interface PDFViewerApplication {
  pdfDocument: any;
  pdfViewer: any;
  pdfLinkService: any;
}

@Component({
  components: { LoadingError, AddPageBookmarkDialog, AddQuickAccessPdfItemDialog, PrintDialog, PdfSpreadModeNotice },
})
export default class PdfViewer extends Vue {
  $refs!: {
    addBookmarkDialog: AddPageBookmarkDialog;
    addQuickAccessPdfItemDialog: AddQuickAccessPdfItemDialog;
    iframe: HTMLIFrameElement;
    printDialog: PrintDialog;
    [key: string]: Vue | Element | Vue[] | Element[];
  };

  pdf: string | null = null;

  /** 表示するPDFを強制指定するパラメータ（プレビュー用） */
  @Prop() pdfUrl!: string | null;

  /** ロード時に表示するページ (1-index) */
  @Prop() initialPage!: number;

  /** ドキュメントID */
  @Prop() documentId!: string;

  @Prop() docRecord!: DocRecord;

  /** 初期ハイライトキーワード（現状プレビューで使用） */
  @Prop() highlight!: string;

  /** 文書内検索・ハイライト用データ */
  @State((state) => state.document.find) find!: FindState;

  @Prop({ type: Boolean }) disableBookmark?: boolean;

  isLoading = true;

  isError = false;

  /** ページ表示しかけ（pagechangingは済んだ） */
  pagesSeenChanging!: Map<number, PDFPageIdentifier>;

  /** ページ表示しかけ（pagerenderedは済んだ） */
  pagesSeenRendered!: Set<number>;

  /** 書籍に含まれるPDFブックマークの一覧 */
  pdfBookmarks: { [pageSeq: number]: (BookmarkTypePdf | QuickAccessItemTypePdf)[] } = {};

  /** iframe内のwindow */
  iframeWindow!: Window;

  get shouldShowPrintUi(): boolean {
    return isAccessible(this.docRecord) && !this.docRecord.isPrintAndDownloadDisallowed && this.$auth.permissions.print;
  }

  get iframeSrc(): string {
    if (!this.pdf) {
      return '';
    }

    const src = `${viewerUrl}&file=${encodeURIComponent(this.pdf)}&enable_permissions=${
      isAccessible(this.docRecord) && this.docRecord.isContentsCopyDisallowed
    }`;

    // externallinktarget=2 は PDF 内のリンクを新しいタブで開く設定
    const params = ['externallinktarget=2'];

    if (this.initialPage) {
      params.push('page=' + this.initialPage);
    }

    if (this.highlight) {
      params.push('search=' + encodeURIComponent(this.highlight));
    } else if (this.find.keywords) {
      params.push('search=' + encodeURIComponent(this.find.keywords.join(' ')));
    }

    if (!this.shouldShowPrintUi) {
      params.push('ls-no-print=1');
    }

    return src + (params.length > 0 ? '#' + params.join('&') : '');
  }

  mounted(): void {
    this.initialize();
  }

  get documentUrl(): string {
    return `${location.origin}/document/${this.docRecord.id}`;
  }

  pageMetadata(pageNumber: number): string {
    const fullUrl = `${this.documentUrl}?view=pdf#page=${pageNumber}`;
    let bibliography: string;
    let pageLabelText = '';
    if (this.docRecord) {
      if (
        pageNumber - 1 >= 0 &&
        isAccessible(this.docRecord) &&
        this.docRecord.folioPerSeq &&
        this.docRecord.folioPerSeq[pageNumber - 1]
      ) {
        const pageLabel = this.docRecord.folioPerSeq[pageNumber - 1];
        if (
          pageLabel.match(/^[0-9]+$/) ||
          pageLabel.match(/^[ivx]+$/) ||
          pageLabel.match(/^[IVX]+$/) ||
          pageLabel.match(/^[一二三四五六七八九十壱弐参拾百千万萬億兆〇]+$/)
        ) {
          pageLabelText = `${pageLabel}頁`;
        }
      }
      const quotes = this.docRecord.type === DocumentTypeEnum.Book ? '『』' : '「」';
      bibliography = `${(this.docRecord.authors || []).join(',')}${quotes[0]}${this.docRecord.title}${quotes[1]}（${
        this.docRecord.publisher
      }、${new Date(this.docRecord.publishedOn).getFullYear()}）${pageLabelText}`;
    } else bibliography = `書誌情報を取得できませんでした。`;
    return `${bibliography} ${fullUrl}`;
  }

  async copyToClipboard({ text, successMessage, errorMessage }: CopyParams): Promise<void> {
    try {
      await navigator.clipboard.writeText(text);
      this.$toast.success(successMessage);
    } catch (e) {
      this.$toast.error(errorMessage);
    }
  }

  async copyPageMetadata(pageNumber: number): Promise<void> {
    await this.copyToClipboard({
      text: this.pageMetadata(pageNumber),
      successMessage: '書誌情報をコピーしました',
      errorMessage: '書誌情報をコピーできませんでした',
    });
  }

  /** PDF.jsのCustom Eventのリスナーを設定する */
  @Watch('pdfUrl')
  @Watch('documentId')
  @Watch('$refs.iframe')
  async initialize(): Promise<void> {
    if (!this.documentId) {
      return;
    }

    const setBookmark = async () => {
      // クイックアクセス・バインダーのいずれかの取得に失敗しても成功したアイテムのみセットして閲覧し続けられるようにする。
      // 失敗した方は空配列で代替するのでデータが消えたという印象を多少与えてしまうが、トーストで通知することで取得に問題があったと明示している
      const results = await this.$repositories.docs.getMyCollectionItemsForDocument(this.documentId);

      let quickAccessItems: QuickAccessItemTypePdf[] = [];
      if (results.quickAccessItemsResult.isFailure()) {
        this.$toast.error('クイックアクセスアイテムの取得に失敗しました');
      } else {
        quickAccessItems = results.quickAccessItemsResult.value.partial
          .map((item): QuickAccessItem => {
            const { docId, ...rest } = item;
            return rest;
          })
          .filter(
            (item): item is QuickAccessItemTypePdf =>
              item.viewType === QuickAccessItemTypePdfPropertiesViewTypeEnum.Pdf,
          );
      }

      let binderItems: BookmarkTypePdf[] = [];
      if (this.$auth.permissions.binder) {
        if (results.binderItemsResult.isFailure()) {
          this.$toast.error('バインダーアイテムの取得に失敗しました');
        } else {
          binderItems = results.binderItemsResult.value.flatMap((binderItem) =>
            binderItem.bookmarks
              .map((bookmark) => {
                const { docId, ...rest } = bookmark;
                return rest;
              })
              .filter((bookmark): bookmark is BookmarkTypePdf => bookmark.viewType === BookmarkViewTypeEnumPdf.Pdf),
          );
        }
      }
      // binder パーミッションがない場合は結果にかかわらずバインダー系は更新しない
      // FIXME: パーミッションがなければそもそもバインダーAPIを叩かないようにする

      this.pdfBookmarks = mapByPageSeq<BookmarkTypePdf | QuickAccessItemTypePdf>([...binderItems, ...quickAccessItems]);
    };

    if (this.pdfUrl) {
      this.pdf = this.pdfUrl;
    } else {
      this.pdf = await this.$repositories.docs.getPdfUrl(this.documentId);
      await this.$nextTick();
    }

    if (!this.pdf || !this.$refs.iframe) {
      return;
    }

    this.isLoading = true;
    this.isError = false;

    this.pagesSeenChanging = new Map();
    this.pagesSeenRendered = new Set();

    // iframe内がabout:blank等ではなく表示させたいページになったことを確認してからイベントリスナを設定する
    const iframeWindow: Window = await new Promise((resolve) => {
      const timer = setInterval(() => {
        if (this.$refs.iframe) {
          const iframeWindow = this.$refs.iframe.contentWindow;
          if (iframeWindow?.location.href.includes('pdf')) {
            console.log('iframeWindow has pdf');
            clearInterval(timer);
            resolve(iframeWindow);
          }
        }
      }, 100);
    });
    this.iframeWindow = iframeWindow;

    // コピーが禁止されている場合コピーできないようにする
    if (isAccessible(this.docRecord) && this.docRecord.isContentsCopyDisallowed) {
      {
        let remainingRetryCount = 100;
        const timer = setInterval(() => {
          if ((<any>iframeWindow).PDFViewerApplicationOptions) {
            // コピー禁止フラグを読み取る設定を有効にしているだけだが、PDF側もコピー禁止フラグが立っているためコピーできなくなる
            (<any>iframeWindow).PDFViewerApplicationOptions.set('enablePermissions', true);
            clearInterval(timer);
          }
          remainingRetryCount -= 1;
          if (remainingRetryCount <= 0) {
            clearInterval(timer);
            throw new Error('PDFの閲覧に必要なPDFビューワーの設定が行えませんでした');
          }
        }, 100);
      }
    }

    // eventBus を取得できるまで待つ
    const eventBus: EventBus = await new Promise((resolve, reject) => {
      let remainingRetryCount = 100;
      const timer = setInterval(() => {
        if ((<any>iframeWindow).PDFViewerApplication && (<any>iframeWindow).PDFViewerApplication.eventBus) {
          const eventBus = (<any>iframeWindow).PDFViewerApplication.eventBus;
          console.log('eventBus ready');
          clearInterval(timer);
          resolve(eventBus);
        }
        remainingRetryCount -= 1;
        if (remainingRetryCount <= 0) {
          clearInterval(timer);
          reject(new Error('PDF の閲覧に必要な eventBus が取得できませんでした'));
        }
      }, 100);
    });

    // 後続の addEventListener が呼ばれないことがあるので、フォールバックとしてクリア処理を入れる
    const timer = setInterval(() => {
      const length = iframeWindow.document.querySelectorAll('[data-loaded]').length;
      const nodeList: NodeListOf<HTMLElement> = iframeWindow.document.querySelectorAll('#errorMessage');
      const errorMessage = nodeList[0] ? nodeList[0].innerText : '';
      if (length > 0) {
        console.log('PDF Viewer lifecycle event: fallback timer for success');
        this.isLoading = false;
        clearInterval(timer);
      } else if (errorMessage !== '') {
        console.log('PDF Viewer lifecycle event: fallback timer for error');
        this.isLoading = false;
        this.isError = true;
        clearInterval(timer);
      }
    }, 1000);

    eventBus.on('unhandledrejection', (event) => {
      console.log("PDF Viewer lifecycle event 'unhandledrejection'", event);
      this.isError = true;
      this.isLoading = false;
      clearInterval(timer);
    });

    eventBus.on('baseviewerinit', (event) => {
      console.log("PDF Viewer lifecycle event 'baseviewerinit'", event);
      if (this.highlight || this.find.keywords) {
        // 検索ハイライトをPDF.jsの機能を利用して行うが、親切に最初のヒット箇所に飛ぶのを無理やりやめさせる
        (<any>iframeWindow).PDFViewerApplication.pdfViewer.findController._scrollMatchesOverride = true;
      }
    });

    // PDF ビューで何かしらのテキストを選択した状態でブラウザの印刷機能を利用すると
    // 10 ページ程度印刷できてしまう問題があるため、動的にスタイルシートを流し込むことで対処する
    const style = iframeWindow.document.createElement('style');
    eventBus.on('documentloaded', (event) => {
      console.log("PDF Viewer lifecycle event 'documentloaded'", event);
      iframeWindow.document.head.appendChild(style);

      if (style.sheet !== null) {
        // TODO tl;dr TypeScript のバージョンを 3.9 系以降にアップグレードすれば、この workaround は不要になる
        // cf. https://github.com/legalscape/wklr/pull/145#discussion_r501507407

        // 利用している TypeScript のバージョン (3.8.3) では、HTMLStyleElement の sheet プロパティの型が
        // CSSStyleSheet ではなく StyleSheet として定義されている。また insertRule() メソッドは
        // CSSStyleSheet インタフェースで定義されているため、style.sheet を CSSStyleSheet インタフェースで
        // あるものとして扱う必要がある。

        // 本来は instanceof CSSStyleSheet としたいのだが、iframe 側で createElement された要素の
        // instanceof による型チェックはうまくいかないため、やむなく型アサーションを利用する
        (style.sheet as CSSStyleSheet).insertRule('@media print { * { display: none; } }', 0);
      }
    });

    eventBus.on('pagerender', async (event) => {
      console.log("PDF Viewer lifecycle event 'pagerender'", event);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const pageNumber = event.pageNumber;
      await setBookmark();
      const eventBus = (<any>iframeWindow).PDFViewerApplication.eventBus;
      eventBus.dispatch('updateBookmarks', {
        pageNumber,
        bookmarks: this.pdfBookmarks[pageNumber - 1] || [],
      });
    });

    eventBus.on('outlineloaded', (event: any) => {
      console.log("PDF Viewer lifecycle event 'outlineloaded'");
      const outline: OutlineItem[] = (<any>iframeWindow).PDFViewerApplication.pdfOutlineViewer._outline;
      if (outline) {
        const resolvePageRecursive: (
          outline: OutlineItem[],
        ) => Promise<(OutlineItem & { pageNum?: number })[]> = async (outline) => {
          return await Promise.all(
            outline.map(async (ol) => {
              const items = await resolvePageRecursive(ol.items);
              try {
                const pageNum = await event.source._pdfDocument.getPageIndex(ol.dest[0]);
                return { ...ol, items, pageNum };
              } catch (e) {
                console.error(e);
                return { ...ol, items };
              }
            }),
          );
        };

        let outlineWithPage: (OutlineItem & { pageNum?: number })[] = outline;
        resolvePageRecursive(outline)
          .then((r) => (outlineWithPage = r))
          .catch((e) => {
            console.error(e);
          })
          .finally(() => {
            this.$emit('outlineloaded', outlineWithPage);
          });
      } else {
        console.log('outline is null or undefined');
      }
    });

    eventBus.on('pagechanging', <any>((event: PDFPageIdentifier) => {
      console.log("PDF Viewer lifecycle event 'pagechanging'", event);
      this.$emit('pagechanging', event);

      if (this.pagesSeenRendered.has(event.pageNumber)) {
        // ページが表示された
        this.$emit('pageview', event);
      } else {
        // pagechangingは発火したがpagerenderedがまだなので待ちリストに追加
        // （labelを記録しておく）
        this.pagesSeenChanging.set(event.pageNumber, event);
      }
    }));

    eventBus.on('pagerendered', <any>((event: {
      source: { div: HTMLDivElement };
      pageNumber: number;
      cssTransform: boolean;
      timestamp: number;
    }) => {
      console.log("PDF Viewer lifecycle event 'pagerendered'", event);
      this.isLoading = false;
      clearInterval(timer);

      // #LSPageURLCopyButton-* の要素は pdf.js 側で生成します
      const copyButton = event.source.div.querySelector(`#LSPageURLCopyButton-${event.pageNumber}`);
      if (copyButton) {
        copyButton.addEventListener('click', () => {
          console.log(copyButton.id);
          this.copyPageMetadata(event.pageNumber);
        });
      }

      // #LSBookmarkMenu-* の要素は pdf.js 側で生成します
      const bookmarkMenu = event.source.div.querySelector(`#LSBookmarkMenu-${event.pageNumber}`);
      if (bookmarkMenu && !this.disableBookmark) {
        bookmarkMenu.classList.add('enabled');
      }

      // #LSAddBookmarkButton-* の要素は pdf.js 側で生成します
      const bookmarkButton = event.source.div.querySelector(`#LSAddBookmarkButton-${event.pageNumber}`);
      if (bookmarkButton && this.disableBookmark) {
        bookmarkButton.addEventListener('click', () => {
          window.alert('現在ブックマークは利用できません');
        });
      }

      // #LSAddBinderButton-* の要素は pdf.js 側で生成します
      const quickAccessButton = event.source.div.querySelector(`#LSAddQuickAccessButton-${event.pageNumber}`);
      if (quickAccessButton && !this.hasQuickAccessItem(event.pageNumber - 1)) {
        quickAccessButton.classList.add('enabled');
        quickAccessButton.addEventListener('click', () => {
          this.$refs.addQuickAccessPdfItemDialog.show(event.pageNumber - 1);
        });
      }

      // #LSAddQuickAccessButton-* の要素は pdf.js 側で生成します
      const binderButton = event.source.div.querySelector(`#LSAddBinderButton-${event.pageNumber}`);
      if (binderButton && this.$auth.permissions.binder) {
        binderButton.classList.add('enabled');
        binderButton.addEventListener('click', () => {
          this.$refs.addBookmarkDialog.show(event.pageNumber - 1);
        });
      }

      if (this.pagesSeenChanging.has(event.pageNumber)) {
        // ページが表示された
        this.$emit('pageview', this.pagesSeenChanging.get(event.pageNumber));
      } else {
        // pagerenderedは発火したがpagechangingがまだなので待ちリストに追加
        this.pagesSeenRendered.add(event.pageNumber);
      }
    }));

    eventBus.on('printExternal', (event) => {
      this.onPrintHandler();
    });

    eventBus.on('updatepagematches', (event) => {
      this.$store.commit('requestUpdateHighlights', event.keywords);
      this.$emit('updatepdfpagematches', event);
    });
  }

  /** PDFの特定の位置に飛ぶ */
  // eslint-disable-next-line @typescript-eslint/ban-types
  async navigate(dest: OutlineItem['dest'] | number, cancelScroll = false): Promise<void> {
    try {
      const pdfViewerApplication = await this.getPDFViewerApplication();
      if (typeof dest === 'number') {
        pdfViewerApplication.pdfViewer.scrollPageIntoView({ pageNumber: dest });
      } else {
        pdfViewerApplication.pdfLinkService.navigateTo(dest);
      }
    } catch (error) {
      console.error(error);
    }
  }

  // Return an array of selected pages (of which value staring with 0)
  listSelectedPages(): { total: number; selected: number[] } {
    if (this.$refs.iframe) {
      const iframeWindow = this.$refs.iframe.contentWindow;
      if (iframeWindow) {
        const PDFViewerApplication = (<any>iframeWindow).PDFViewerApplication;
        const pages: { total: number; selected: number[] } = PDFViewerApplication.pdfViewer.listSelectedPages;
        // pdf.js から渡されるページ番号は 1-indexed なので 0-indexed に直す
        const zeroIndexedSelectedPages = pages ? pages.selected.map((page) => page - 1) : [];
        return {
          total: pages ? pages.total : 0,
          selected: zeroIndexedSelectedPages,
        };
      }
    }
    return {
      total: 0,
      selected: [],
    };
  }

  get page(): number {
    const iframeWindow = this.$refs.iframe.contentWindow;
    if (iframeWindow) {
      const PDFViewerApplication = (<any>iframeWindow).PDFViewerApplication;
      const pageNumber = PDFViewerApplication.pdfViewer.currentPageNumber;
      return Number(pageNumber);
    }
    return 0;
  }

  get inViewContentUrl(): string {
    return `/document/${this.documentId}?view=pdf#page=${this.page}`;
  }

  updateAddedBookmark(addedBookmark: BookmarkTypePdf | QuickAccessItemTypePdf): void {
    const { pageSeq } = addedBookmark;
    if (this.pdfBookmarks[pageSeq]) {
      this.pdfBookmarks[pageSeq].push(addedBookmark);
    } else {
      this.pdfBookmarks[pageSeq] = [addedBookmark];
    }

    const eventBus = (<any>this.iframeWindow).PDFViewerApplication.eventBus;
    eventBus.dispatch('updateBookmarks', {
      pageNumber: pageSeq + 1,
      bookmarks: this.pdfBookmarks[pageSeq] || [],
    });
  }

  /** pdf.js の iframe から PDFViewerApplication を取得する（ pdfDocument が null じゃなくなるまで待ってから返却する） */
  getPDFViewerApplication(): Promise<PDFViewerApplication> {
    return new Promise((resolve, reject) => {
      let remainingRetryCount = 100; // 10秒間待つ
      const timer = setInterval(() => {
        if (this.$refs.iframe) {
          const iframeWindow = this.$refs.iframe.contentWindow;
          if (iframeWindow) {
            const app = (<any>iframeWindow).PDFViewerApplication;
            if (app !== undefined && app.pdfDocument !== null) {
              clearInterval(timer);
              resolve(app);
            }
          }
        }
        remainingRetryCount--;
        if (remainingRetryCount < 1) {
          clearInterval(timer);
          reject(new Error("couldn't get PDFViewerApplication"));
        }
      }, 100);
    });
  }

  onPrintHandler(): void {
    if (!isAccessible(this.docRecord)) return;
    if (this.docRecord.isPrintAndDownloadDisallowed) {
      this.$toast.info('コンテンツ提供元の意向により、印刷機能が禁止されています');
      return;
    }
    if (!this.$auth.permissions.print) {
      this.$toast.info('ご利用のアカウントは印刷機能を利用することができません');
      return;
    }
    this.$refs.printDialog.show(this.docRecord, this.$auth.user);
  }

  hasQuickAccessItem(pageSeq: number): boolean {
    if (Object.prototype.hasOwnProperty.call(this.pdfBookmarks, pageSeq)) {
      // 各ページ内でQuickAccessItemTypePdfのインスタンスのみをフィルタ
      const quickAccessItems = this.pdfBookmarks[pageSeq].filter((item) => !('binderId' in item));
      return quickAccessItems.length > 0;
    }
    return false;
  }
}
