import queryString from 'query-string';
import { Viewer } from '@/types/Viewer';
import { Nullable } from '@/types/nullable';
import * as VueRouter from 'vue-router';
import { PartialSearchQuery } from '@/types/SearchQuery';
import rison from 'rison';

/**
 * isNaN の代わりに null を返す Number コンストラクタ
 * array だった場合にも null を返す
 */
const parseOrNull = (hashValue: string | unknown[] | null): number | null => {
  if (hashValue === null) return null;
  if (Array.isArray(hashValue)) return null;
  const num = Number(hashValue);
  return Number.isNaN(num) ? null : num;
};

/**
 * URL 文字列の viewer パラメータを変更して返す
 * @param urlString 変換元の URL 文字列
 * @param viewer セットしたい viewer type ( web | pdf )
 * @returns 返還後の URL 文字列
 */
export const replaceViewerType = (urlString: string, viewer: Viewer): string => {
  return _replaceViewerType(new URL(urlString), viewer).toString();
};

/**
 * URL 文字列のハッシュに文献閲覧ページの表示位置の情報をセットして返す
 * @param urlString 変換元の URL 文字列
 * @param args 位置情報
 * @returns 返還後の URL 文字列
 */
export const replaceHashAndViewerType = (urlString: string, args: PageDocumentHash): string => {
  const url = new URL(urlString);
  const viewerTypeApplied = _replaceViewerType(url, args.type);
  const hashModified = _replaceHash(viewerTypeApplied, args);
  return hashModified.toString();
};

const _replaceViewerType = (url: URL, viewer: Viewer): URL => {
  if (url.searchParams.has('view')) {
    url.searchParams.set('view', viewer);
  } else {
    url.searchParams.append('view', viewer);
  }
  return url;
};

export const _replaceHash = (url: URL, args: PageDocumentHash): URL => {
  const hash = queryString.parse('');
  switch (args.type) {
    case 'pdf':
      hash['page'] = args.page.toString();
      break;
    case 'web':
      hash['key'] = args.key.toString();
      if (args.series !== null) hash['series'] = args.series.toString();
      break;
  }
  url.hash = queryString.stringify(hash);
  return url;
};

/**
 * URL の searchParams から検索クエリ部分を書き換えて返す
 * @param urlString 変換元の URL 文字列
 * @param q 新しく適用する URLSearchParams
 */
export const replaceQuery = (urlString: string, q: PartialSearchQuery): string => {
  const url = new URL(urlString);
  url.searchParams.set('q', rison.encode_object<PartialSearchQuery>(q));
  return url.toString();
};

export const getPageDocumentHashFromUrl = (urlString: string): Nullable<PageDocumentHash> => {
  const url = new URL(urlString);
  const view = url.searchParams.get('view');

  const hash = queryString.parse(url.hash);
  const page = parseOrNull(hash['page']);
  const key = parseOrNull(hash['key']);
  const series = parseOrNull(hash['series']);

  switch (view) {
    case 'pdf':
      if (page !== null) {
        return { type: 'pdf', page };
      } else {
        return null;
      }
    case 'web':
      if (key !== null) {
        return { type: 'web', key, series };
      } else {
        return null;
      }
    default:
      return null;
  }
};

type PageDocumentHashWeb = {
  type: 'web';
  key: number;
  series: Nullable<number>;
};

type PageDocumentHashPdf = {
  type: 'pdf';
  page: number;
};

export type PageDocumentHash = PageDocumentHashWeb | PageDocumentHashPdf;

type ReferenceInfo = {
  reference_doc?: string;
  reference_key?: string;
  reference_series?: string;
};

export const generateReferenceLocation = (urlString: string, reference: ReferenceInfo): VueRouter.Location => {
  const url = new URL(urlString);
  return {
    query: {
      ...Object.fromEntries(url.searchParams.entries()),
      ...reference,
    },
    hash: url.hash,
  };
};

export const generateReferenceDocInfoFromRoute = (
  route: VueRouter.Route,
): { referenceDocId: string; referenceKey: number | null; referenceSeries: number | null } => {
  const referenceDocId = (route.query.reference_doc as string) || '';
  const referenceKey = route.query.reference_key ? Number.parseInt(route.query.reference_key as string) : null;
  const referenceSeries = route.query.reference_series ? Number.parseInt(route.query.reference_series as string) : null;
  return {
    referenceDocId,
    referenceKey,
    referenceSeries,
  };
};

/** 与えられた文字列を URL に変換して、無効な URL 文字列だった場合には null を返す */
export const generateURLFromString = (urlString: string): URL | null => {
  try {
    return new URL(urlString);
  } catch (_) {
    return null;
  }
};
