import { Component, Vue, Prop } from 'nuxt-property-decorator';
import WklrIconBtn from '@/components/shared/wklr-icon-btn.vue';
import { fragmentBibliography, fragmentUrl } from '@/utils/copyFragmentUtils';
import { DocRecord } from 'wklr-backend-sdk/models';

@Component({ components: { WklrIconBtn } })
export default class ShareFragmentButton extends Vue {
  @Prop()
  isFirstContent!: boolean;
  @Prop()
  sectionKey!: string;
  @Prop()
  contentSeries!: string;
  @Prop()
  record!: DocRecord;

  get fragmentUrl(): string {
    return this.isFirstContent
      ? fragmentUrl(this.record, this.sectionKey)
      : fragmentUrl(this.record, this.sectionKey, this.contentSeries);
  }

  async copyFragmentInfo(): Promise<void> {
    const target = this.isFirstContent ? 'セクション' : '段落';
    try {
      await navigator.clipboard.writeText(`${fragmentBibliography(this.record)} ${this.fragmentUrl}`);
      this.$toast.success(`${target}の書誌情報とURLをコピーしました`);
    } catch (e) {
      this.$toast.error(`${target}の書誌情報とURLをコピーできませんでした`);
    }
  }
}
