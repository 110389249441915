import { getShopifyMultipassAuthUrl, shopifyDocDetailsPath } from '@/utils/shopifyUtils';
export default defineComponent({
  props: {
    record: {
      type: Object,
      required: true
    }
  },
  setup: function setup() {
    var getBookLink = function getBookLink(docId) {
      return getShopifyMultipassAuthUrl(shopifyDocDetailsPath(docId));
    };
    return {
      getBookLink: getBookLink
    };
  }
});