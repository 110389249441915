<template>
  <confirm-dialog
    ref="dialog"
    v-model="webItemDialog"
    icon="mdi-bookmark"
    title="クイックアクセス"
    confirm-label="クイックアクセスに登録する"
    message="この内容でクイックアクセスに登録します"
    :disabled="descriptionValidator(itemDescription) !== true"
    @confirm="itemCommitHandler"
  >
    <div class="webview-dialog-body">
      <p>
        文献名 {{ record.title }}<br />
        {{ headingText }}
      </p>
    </div>
    <v-textarea v-model="itemDescription" label="メモ" outlined :rules="[descriptionValidator]" />
  </confirm-dialog>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'nuxt-property-decorator';
import ConfirmDialog from '@/components/common/confirm-dialog.vue';
import { descriptionValidator } from '@/utils/binderFoldersUtils';
import { getTocByKey } from '@/utils/webViewerUtils';
import { DocRecord, QuickAccessItem } from 'wklr-backend-sdk/models';

@Component({
  components: { ConfirmDialog },
})
export default class AddQuickAccessWebItemDialog extends Vue {
  $refs!: {
    dialog: ConfirmDialog;
  };

  descriptionValidator = descriptionValidator;

  @Prop()
  record!: DocRecord;

  @Prop()
  success?: (response: QuickAccessItem) => void;

  selectedSectionKey: number | null = null;
  get webItemDialog(): boolean {
    return this.selectedSectionKey !== null;
  }
  set webItemDialog(value) {
    if (!value) {
      this.selectedSectionKey = null;
    } else {
      console.warn('set selectedSectionKey to activate webItemDialog');
    }
  }

  get headingText(): string {
    if (this.selectedSectionKey) {
      const toc = getTocByKey(this.selectedSectionKey, this.record.toc);
      if (toc !== undefined) {
        return toc.label;
      }
    }

    return '';
  }

  itemDescription = '';

  async itemCommitHandler() {
    if (this.selectedSectionKey === null) {
      this.webItemDialog = false;
      return;
    }

    try {
      const response = await this.$repositories.quickAccess.createWebItem(
        this.record.id,
        this.selectedSectionKey,
        this.headingText,
        new Date(this.record.publishedOn).getTime() / 1000,
        this.itemDescription,
      );

      this.$toast.success('クイックアクセスに登録しました');
      this.webItemDialog = false;
      if (this.success) {
        this.success(response);
      }
    } catch (error) {
      this.$refs.dialog.reactivate();
      console.error(error);
      this.$toast.error('クイックアクセスへの登録に失敗しました');
    }
  }

  async show(sectionKey: number) {
    if (!(this.record.toc && this.record.toc.byKey[sectionKey])) {
      console.error(
        `存在しない見出しをクイックアクセスに登録しようとしました。docId=${this.record.id}, sectionKey=${sectionKey}`,
      );
      this.$toast.error('クイックアクセスへの登録に失敗しました');
      return;
    }

    this.selectedSectionKey = sectionKey;
    this.itemDescription = '';
  }
}
</script>
