<template>
  <v-menu offset-y open-on-hover>
    <template #activator="{ on }">
      <div class="bookmark-status" v-on="on">
        <wklr-icon type="bookmark" :color="color" size="small" />
        <span v-if="hasAnyItems" class="badge">{{ bookmarks.length + quickAccessItems.length }}</span>
      </div>
    </template>
    <v-list dense class="bookmark-list" width="300">
      <template v-if="hasAnyItems">
        <v-subheader> <wklr-icon type="bookmark" size="x-small" :left="true" />マイ・コレクション </v-subheader>
        <v-list-item v-for="item in quickAccessItems" :key="item.id" to="/collections">
          <v-list-item-content>
            <v-list-item-title>クイックアクセス</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-for="bookmark in bookmarks" :key="bookmark.id" :to="`/binder/${bookmark.binder.id}`">
          <v-list-item-content>
            <v-list-item-title>
              {{ bookmark.binder.name }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
      <template v-else>
        <v-list-item>
          <p class="blank-state">このセクションはどこにも登録されていません</p>
        </v-list-item>
      </template>
      <template v-if="hasNotQuickAccessItem">
        <v-list-item @click="$emit('click-quickaccess')">
          <wklr-icon type="add" size="small" :left="true" />
          <v-list-item-content>
            <v-list-item-title> クイックアクセスに登録する </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
      <template v-if="hasAuthOfBinder">
        <v-list-item @click="$emit('click-binder')">
          <wklr-icon type="add" size="small" :left="true" />
          <v-list-item-content>
            <v-list-item-title> バインダーに登録する </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
  </v-menu>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'nuxt-property-decorator';
import WklrIcon from '@/components/shared/wklr-icon.vue';
import { BookmarkTypeWebWithBinderDetail } from './web-viewer';
import { BinderTypeEnum, QuickAccessItemTypeWeb } from 'wklr-backend-sdk/models';

// TODO: ToC パネルにバインダー機能が追加されたらブックマークをクリックした際に ToC パネルが開くようにする
@Component({ components: { WklrIcon } })
export default class BookmarkStatus extends Vue {
  @Prop() bookmarks!: BookmarkTypeWebWithBinderDetail[];
  @Prop() quickAccessItems!: QuickAccessItemTypeWeb[];

  get hasAnyItems() {
    return this.bookmarks.length > 0 || this.quickAccessItems.length > 0;
  }

  get hasNotQuickAccessItem() {
    return this.quickAccessItems.length == 0;
  }

  get hasAuthOfBinder() {
    return this.$auth.permissions.binder;
  }

  get color() {
    if (this.hasAnyItems) {
      return 'primary';
    }
    return 'default';
  }

  linkToBookmark(bookmark: BookmarkTypeWebWithBinderDetail) {
    switch (bookmark.binder.type) {
      case BinderTypeEnum.Normal:
        return `/binder/${bookmark.binder.id}`;
      case BinderTypeEnum.QuickAccess:
        return '/collections';
    }
  }
}
</script>

<style lang="scss" scoped>
.bookmark-status {
  position: relative;
  display: inline-block;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 4px;

  > .badge {
    position: absolute;
    left: -5px;
    top: -5px;
    display: inline-block;
    width: 16px;
    height: 16px;
    background: $primary;
    color: white;
    line-height: 12px;
    font-family: 'Noto Sans JP', sans-serif;
    font-weight: bold;
    font-size: 9px;
    text-align: center;
    border: 2px solid white;
    border-radius: 50%;
  }
}

.bookmark-list {
  .blank-state {
    padding: 16px 0;
  }

  // Vuetify のスタイルを上書き
  .v-subheader {
    height: auto;
    margin: 0 16px 6px;
    padding: 6px 0;
    border-bottom: 1px solid #e0e0e0;
  }
  // Vuetify のスタイルを上書き
}
</style>
