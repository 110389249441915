<template>
  <div v-show="selectionPosition !== null" ref="selection-toolbox" class="selection-toolbox" :style="{ top, left }">
    <template v-if="!disableHighlight">
      <wklr-icon-with-text-btn type="add" size="small" @click="$emit('click-highlight-button')"
        >バインダーに登録する</wklr-icon-with-text-btn
      >
      <span class="separator" />
    </template>
    <wklr-icon-with-text-btn type="report" size="small" @click="$emit('click-correction-report')"
      >誤りを報告する</wklr-icon-with-text-btn
    >
    <span class="separator" />
    <wklr-icon-btn type="copy" size="small" title="選択範囲をコピーする" @click="$emit('click-copy-selection')" />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import { SelectionPosition } from './web-viewer';
import WklrIconBtn from '@/components/shared/wklr-icon-btn.vue';
import WklrIconWithTextBtn from '@/components/shared/wklr-icon-with-text-btn.vue';

// FIXME: もともと選択範囲の右上に配置されていたが、複数段落を選択するとテキストとエレメントの右端がずれてしまうため、暫定的に左寄せにした。選択範囲の品質を上げたら位置計算を実装して正しい位置に配置する

/** 選択範囲とツールボックスの間の余白 */
const VERTICAL_MARGIN = 4;

@Component({ components: { WklrIconBtn, WklrIconWithTextBtn } })
export default class SelectionToolbox extends Vue {
  $refs!: {
    'selection-toolbox': HTMLElement;
    [key: string]: Vue | Element | Vue[] | Element[];
  };

  @Prop()
  selectionPosition!: SelectionPosition | null;

  @Prop({ type: Boolean })
  disableHighlight?: boolean;

  get height(): number {
    return (this.$el as HTMLElement).offsetHeight;
  }

  get top(): string {
    if (this.selectionPosition === null) return '0px';
    return `${this.selectionPosition.top - this.height - VERTICAL_MARGIN}px`;
  }

  get left(): string {
    if (this.selectionPosition === null) return '-500px'; // 選択されていない場合は画面外に飛ばす
    return `${this.selectionPosition.left}px`;
  }
}
</script>

<style lang="scss" scoped>
.selection-toolbox {
  display: flex;
  align-items: center;
  position: absolute;
  z-index: $layer-document-selection-toolbox;
  top: 0;
  left: 0;
  transition: opacity 0.1s linear;

  font-family: 'Noto Sans JP', sans-serif;
  background: white;
  padding: 2px;
  border-radius: 4px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2), 0px 1px 10px 0px rgba(0, 0, 0, 0.12),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14);

  > .separator {
    background: #e0e0e0;
    width: 1px;
    align-self: stretch;
    display: inline-block;
    margin: 4px 0;
  }
}
</style>
