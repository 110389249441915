import {
  DocSection,
  HighlightColorEnum,
  HighlightWithNaturalId,
  SubsectionTypeEnum,
  TextContentTypeEnum,
  Toc,
  TocNode,
} from 'wklr-backend-sdk/models';
import { SectionContentEx } from '@/types/web-viewer';
import { byKeySorter } from '@/utils/arrayUtil';
import { HighlightedContentWithColor } from '@/types/binder-folders';

/**
 * WebViewer コンポーネントで利用する、セクションの構造体をレンダリング用に調整したもの
 * 閲覧開始時には Toc の情報から生成され、各セクションの内容を API で取得するごとに上書きされる
 * TODO: Omit しているフィールドはオプショナルにするなどして活用する方法もある
 */
export type WebviewerSection = Omit<DocSection, 'docId' | 'headPageSeq' | 'contents'> & {
  type: 'section';
  state: 'beforeLoad' | 'loading' | 'loaded';
  contents: SectionContentEx[];
  parent: number;
  depth: number;
};

/**
 * WebViewer コンポーネントで利用する、コンテントの構造体にローティング状態を追加したもの
 * TODO: SectionContentEx はフロント用の型だと思うのでこれと合わせて整理したい
 */
export type WebviewerContent = SectionContentEx | WebviewerSection;

/**
 * TOCを読んでこのセクションの深さを調べる
 * @param key
 * @param tocByKey
 */
export function depthByKey(key: number, tocByKey: TocNode[]): number {
  const tocItem = tocByKey[key];
  let depth = 0;
  let cursor = tocItem;
  while (cursor && tocByKey[cursor.parent]) {
    ++depth;
    cursor = tocByKey[cursor.parent];
  }
  return depth;
}

/**
 * Section API のレスポンスを取得して、表示用のコンテントに変換して返す
 * NOTE: 現状の仕様だと API レスポンス中の sections.contents 以外の情報は捨てられている。必要なら再検討
 * @param section
 * @param depth
 */
export function convertSectionResponseToContents(section: DocSection, depth: number): SectionContentEx[] {
  let pageSecOffset = 0;
  const lastIndex = section.contents.length - 1;
  return section.contents.map((content, i) => {
    const topPageSeq = section.headPageSeq + pageSecOffset;

    if (content.type === TextContentTypeEnum.Text) {
      // FIXME: 内容物によって値が変わるものをシーケンスにするのがよくないのでやめたい。ページ概念の取り扱いを変えたらやめられそう
      pageSecOffset += (content.text.match(/<br/g) || []).length;
    }

    return {
      ...content,
      depth,
      open: 0,
      close: 0,
      parent: section.key,
      firstContent: i === 0,
      lastContent: i === lastIndex,
      topPageSeq,
      bottomPageSeq: section.headPageSeq + pageSecOffset,
    };
  });
}

/**
 * 再起的に辿ってそれぞれのセクションに親となる key を設定する
 * TODO: Toc に parent の情報が乗る様になったら不要になるのでそうしたい（サーバ側実装が必要）
 * @param currentNode
 * @param tocArray
 * @param parent
 */
function traverse(currentNode: TocNode, tocArray: TocNode[], parent: number): WebviewerSection[] {
  const self: WebviewerSection = {
    type: 'section',
    state: 'beforeLoad',
    contents: [],
    key: currentNode.key,
    parent: parent,
    label: currentNode.label,
    depth: currentNode.depth,
  };

  const children = currentNode.children
    .filter((childKey) => childKey != self.key) // 自己参照していると stack overflow になるので念のために除外
    .map((childKey) => traverse(tocArray[childKey], tocArray, self.key))
    .flat();

  return [self, ...children];
}

/**
 * Toc の情報からセクションの配列を生成する
 * ドキュメント表示時の初期の構造となる
 * @param toc
 */
export function generateWebviewerSections(toc: Toc | null): WebviewerSection[] {
  if (toc === null || toc.children === undefined) return [];

  const sorter = byKeySorter<WebviewerSection>('key');
  // toc.byKey はルートを持たない構造となっており、ルートは roc 自体が持っているのでそれを利用する（なので toc の children と toc.byKey.+ の children は若干意味が違う）
  const sections = toc.children
    .map((childKey) => traverse(toc.byKey[childKey], toc.byKey, -1))
    .flat()
    .sort(sorter); // Toc の key の順番に並び替える
  console.log('generateWebviewerSections: ', sections);
  return sections;
}

export type SectionIndex =
  | { type: 'section'; key: number; keyForVue: string }
  | { type: 'content'; key: number; series: number; parent: number; keyForVue: string; refForVue: string };

/**
 * WebviewerSection の配列を SectionIndex の配列に変換する
 * もともと section と contents を別々に管理していたが、整合性のチェックをやめて処理の流れを一方向にするようにした
 * また、ここで新しいオブジェクトを作ってしまうと各要素の実態が変わってしまい、すべてが再レンダリングされてしまうため、実態は持たないようにした
 * @param sections
 */
export function convertSectionsIntoIndex(sections: WebviewerSection[]): SectionIndex[] {
  return sections
    .map((section) => {
      // console.log('section index', index);
      switch (section.state) {
        case 'beforeLoad':
        case 'loading':
          return [
            {
              type: 'section' as const,
              key: section.key,
              keyForVue: `key-${section.key}`,
            },
          ];
        case 'loaded':
          return section.contents
            .filter((content) => content.type === TextContentTypeEnum.Text)
            .map((content) => {
              return {
                type: 'content' as const,
                key: section.key,
                series: content.series,
                parent: content.parent,
                keyForVue: `series-${content.series}`,
                refForVue: `content-${content.series}`,
              };
            });
      }
    })
    .flat();
}

/**
 * 渡された WebviewerContent が SectionContentEx かを確認する
 * 具体的にはロード済みかどうかを確認する
 * @param content
 */
export function isSectionContent(content: WebviewerContent): content is SectionContentEx {
  return content.type === TextContentTypeEnum.Text || content.type === SubsectionTypeEnum.Subsection;
}

/**
 * セクションが所属するノードを辿ってすべてのセクションの key を配列で返す
 * TODO: toc-panel コンポーネント側で処理するタスクかもしれない
 * @param key
 * @param sections
 */
export function getAncesterSection(key: number, sections: WebviewerSection[]): number[] {
  let current = sections[key];
  const result = [];
  while (current !== undefined && current.parent !== -1) {
    result.push(current.parent);
    current = sections[current.parent];
  }
  return result;
}

/**
 * フラットな配列を key をキーとしたハッシュマップにマップし直す
 * @param arr
 * @returns
 */
export const mapByKey = <T extends { key: number }>(arr: T[]): { [key: number]: T[] } => {
  const result: { [key: number]: T[] } = {};
  arr.forEach((item) => {
    if (Array.isArray(result[item.key])) {
      result[item.key].push(item);
    } else {
      result[item.key] = [item];
    }
  });
  return result;
};

/**
 * フラットな配列を series をキーとしたハッシュマップにマップし直す
 * @param arr
 * @returns
 */
export const mapBySeries = <T extends { series: number }>(arr: T[]): { [key: number]: T[] } => {
  const result: { [key: number]: T[] } = {};
  arr.forEach((item) => {
    if (Array.isArray(result[item.series])) {
      result[item.series].push(item);
    } else {
      result[item.series] = [item];
    }
  });
  return result;
};

/**
 * API レスポンスに color コードを挿入して表示用のオブジェクトの配列を生成する
 * @param response
 * @returns
 */
export const extractHighlightContentsWithColor = (
  color: HighlightColorEnum,
  highlights: HighlightWithNaturalId[],
): HighlightedContentWithColor[] => {
  return highlights.flatMap((hl) => hl.highlightedContents.map((hl) => ({ ...hl, color })));
};

export const sectionKeysWithAncestorsAndDescendants = (toc: Toc | null, sectionKey: number): number[] => {
  if (toc === null) return [];
  const findAncestors = (key: number, ret: number[]) => {
    const tocNode = toc.byKey[key];
    if (!tocNode || tocNode.parent === -1) {
      return;
    }
    ret.push(tocNode.parent);
    findAncestors(tocNode.parent, ret);
  };
  const findDescendatns = (key: number, ret: number[]) => {
    const tocNode = toc.byKey[key];
    if (!tocNode || !toc.children || !tocNode.children.length) {
      return;
    }
    for (const c of tocNode.children) {
      ret.push(c);
      findDescendatns(c, ret);
    }
  };
  const ret: number[] = [];
  findAncestors(sectionKey, ret);
  ret.push(sectionKey);
  findDescendatns(sectionKey, ret);

  return ret;
};

export const getTocByKey = (key: number, toc: Toc | null): TocNode | undefined => {
  // TODO: this.record.toc.byKey を直接参照している箇所をこれに置き換える
  // TODO: this.record.toc が undefined の場合があるかもしれない
  if (toc === null) return undefined;

  const tocNode = toc.byKey[key];
  if (tocNode === undefined) {
    console.warn('no toc found by key: ', key);
  }
  return tocNode;
};

const CITATION_TEXT_PATTERNS = [
  /^(?<authors>[^・」]+)?「(?<title>[^」]+)」(?<ignored>.+)?$/,
  /^(?<authors>[^・]+)・(?<title>.+)(?<ignored>〔[^・，0-9〔〕]+[・，][0-9～・]+〕.*)$/,
  /^(?<authors>[^・]+)・(?<title>.+)(?<ignored>（[^・，0-9（）]+[・，][0-9～・]+）.*)$/,
  /^(?<title>.+[判決](昭和|平成)[0-9・元]+)(?<ignored>.+)?$/,
  /^(?<title>[^・]+)(?<ignored>〔[^・，0-9〔〕]+[・，][0-9～・]+〕.*)$/,
  /^(?<title>.+)(?<ignored>（(有斐閣|中央経済社|第一法規|弘文堂|商事法務|商事法務研究会|信山社出版|日本評論社|中央経済社（発売中央経済グループパブリッシング）|法曹会|判例タイムズ社|金融財政事情研究会（発売きんざい）|青林書院|新日本法規出版|八千代出版|成文堂|経済法令研究会|新世社（発売サイエンス社）|同文舘出版|有信堂高文社|勁草書房)）)$/,
];

/**
 * `蓮井良憲「監査役・会計監査人の監査報告書」民商85巻4号` のような引用形式のテキストをそのまま外部検索エンジン（国立国会図書館等）に渡しても
 * 望ましい結果が出ないため、検索に適したクエリ文字列を生成する
 *
 * @param text Webビューデータに埋め込まれているリンク先を説明する引用形式のテキスト
 */
export function buildSearchQueryString(text: string): string {
  const match = (() => {
    // パターンを上から試し、最初にヒットするものを採用する
    for (const pattern of CITATION_TEXT_PATTERNS) {
      const match = text.match(pattern);

      if (match) {
        return match;
      }
    }
  })();

  if (!match || !match.groups) {
    // 何もヒットしなかったので諦める
    return text;
  }

  let { authors, title } = match.groups;

  // 判例評釈を引用する際は一般にタイトルを記載する代わりに「判批」と書くためこれらの文字列は意味のあるタイトルではない
  if (title === '判批' || title === '判解') {
    // あまりできる工夫がないので諦める
    return text;
  }

  const query: string[] = [];

  if (authors) {
    // 「編著」などを除去
    authors = authors.replace(/(編著?|編集代表|ほか|監修)+($|\s＝\s)/g, ' ');
    // 区切り記号を除去
    authors = authors.replace(/\s＝\s/g, ' ');
    query.push(authors);
  }

  // 版情報を囲う括弧を除去
  title = title.replace(/[＜〔（]([第0-9０-９版新全補訂改]+?)[）〕＞]/g, ' $1 ');
  // 区切り記号を除去
  title = title.replace(/——/g, ' ');
  query.push(title);

  return query.join(' ').replace(/  +/g, ' ').trim() || text;
}
