import { Component, Prop, Vue, Watch } from 'nuxt-property-decorator';
import DocumentListItem from '@/components/document-list-item.vue';
import * as Constants from '@/constants';
import { DocRecord, DocumentTypeEnum } from 'wklr-backend-sdk/models';
import { formatYmd } from '@/utility';
import { NonPermitDocumentError } from '@/utils/errorUtils';

@Component({ components: { DocumentListItem } })
export default class AvailabilityPopup extends Vue {
  formatYmd = formatYmd;

  @Prop() sourceDocRecord!: DocRecord;
  @Prop() targetDocumentId!: string | null;

  @Watch('targetDocumentId')
  visibilityHandler() {
    if (this.targetDocumentId === null) {
      this.clear();
    } else {
      this.setTimer(this.targetDocumentId);
    }
  }

  /** 表示内容 */
  data: DocRecord | 'loading' | null = null;

  /** Legalscapeに収録されていますタイマー */
  timer: number | null = null;

  get maxWidth(): string {
    const parentRectWidth = this.$parent?.$el.getBoundingClientRect().width || 0;
    return `calc(${parentRectWidth}px * 0.6)`;
  }

  /**
   * コンメンタールから法律文を参照した場合にのみ準拠する法律の施行日を注意文言として表示する
   */
  get showReferenceWarning(): boolean {
    if (this.data === null) return false;
    if (this.data === 'loading') return false;
    if (this.data.type !== DocumentTypeEnum.Law) return false;
    if (!this.sourceDocRecord.isKommentar) return false;
    return true;
  }

  /**
   * タイマー起動
   * @param id タイムアウト時にポップアップに表示する文書のID
   */
  setTimer(id: string) {
    this.clear();
    this.timer = window.setTimeout(async () => {
      this.data = 'loading';
      try {
        this.data = await this.$repositories.docs.get(id);
        console.log('DATA', this.data);
      } catch (e) {
        console.log(e);
        if (e instanceof NonPermitDocumentError) {
          this.data = e.record;
        } else {
          this.data = null;
        }
      }
    }, Constants.Document.WebView.AvailabilityPopupWait);
  }

  /** タイマーキャンセルしてポップアップも閉じる */
  clear() {
    if (this.timer !== null) {
      clearTimeout(this.timer);
    }

    this.data = null;
  }
}
