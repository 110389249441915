import { HighlightedContent } from 'wklr-backend-sdk/models';

/**
 * セレクションから key に対応した選択されている範囲の content に対応した HTMLElement の配列を返す。 anchorNode, focusNode は含まれない
 * @param selection
 * @param key
 * @returns
 */
export const getMiddleNodesFromSelectionByKey = (selection: Selection, key: string | undefined): HTMLElement[] => {
  if (key === undefined) return [];
  const range = selection.getRangeAt(0);
  const selector = `.body-box-content[data-parent-key="${key}"]`;
  if (range.commonAncestorContainer instanceof HTMLElement) {
    const sectionNodes = range.commonAncestorContainer.querySelectorAll(selector);
    return Array.prototype.filter.call(sectionNodes, (node) => selection.containsNode(node));
  } else {
    return [];
  }
};

/**
 * 開始と終了が同じ場合の HighlightedContent と text のペアを返す
 * @param rootNode 対象となる body-box-content
 * @param startOffset
 * @param endOffset
 * @param node anchorNode で指定される text node
 * @returns
 */
export const getHighlightFromSingleNode = (
  rootNode: HTMLElement,
  startOffset: number,
  endOffset: number,
  startNode: Node,
  endNode: Node,
): { highlightedContents: HighlightedContent[]; text: string } => {
  const { series } = rootNode.dataset;
  const text = getTextFromNode(rootNode);
  const startPosition = getNodeOffsetForRootNode(startNode, rootNode) + startOffset;
  const endPosition = getNodeOffsetForRootNode(endNode, rootNode) + endOffset;

  return {
    highlightedContents: [
      {
        series: Number(series),
        startPosition,
        endPosition,
      },
    ],
    text,
  };
};

/**
 * 開始と終了が別ノード場合の HighlightedContent と text のペアを返す
 * @param startRootNode 開始 node を含む body-box-content
 * @param startOffset
 * @param endRootNode  終了 node を含む body-box-content
 * @param endOffset
 * @param startNode 開始 node
 * @param endNode 終了 node
 * @param middleNodes 開始と終了の間にある body-box-content
 * @returns
 */
export const getHighlightsFromMultiNode = (
  startRootNode: HTMLElement,
  startOffset: number,
  endRootNode: HTMLElement,
  endOffset: number,
  startNode: Node,
  endNode: Node,
  middleNodes: HTMLElement[],
): { highlightedContents: HighlightedContent[]; text: string } => {
  const startParagraphOffset = getNodeOffsetForRootNode(startNode, startRootNode) + startOffset;
  const endParagraphOffset = getNodeOffsetForRootNode(endNode, endRootNode) + endOffset;

  const nodes = [startRootNode, ...middleNodes, endRootNode];
  const nodesWithText = nodes.map((node) => ({ node, text: getTextFromNode(node) }));
  const highlightedContents: HighlightedContent[] = nodesWithText.map(({ node, text }) => {
    const { series } = node.dataset;
    return {
      series: Number(series),
      startPosition: 0,
      endPosition: text.length,
    };
  });

  highlightedContents[0].startPosition = startParagraphOffset;
  highlightedContents[highlightedContents.length - 1].endPosition = endParagraphOffset;

  return {
    highlightedContents,
    text: nodesWithText.map((n) => n.text).join(''),
  };
};
/**
 * rootNode において node の offset 位置までの文字数を返す
 * @param node
 * @param nodeOffset
 * @param rootNode
 * @returns number
 */
export const getNodeOffsetForRootNode = (node: Node, rootNode: Node): number => {
  if (node === rootNode) return 0;
  if (node.parentNode) {
    const nodes = node.parentNode.childNodes;
    let len = 0;
    for (let i = 0; i < nodes.length; i++) {
      const currentNode = nodes[i];
      if (currentNode === node) {
        break;
      } else {
        if (currentNode instanceof Text) {
          len += currentNode.textContent ? currentNode.textContent.length : 0;
        }
        if (currentNode instanceof HTMLElement) {
          len += currentNode.innerText.length || 0;
        }
      }
    }
    return getNodeOffsetForRootNode(node.parentNode, rootNode) + len;
  }
  return 0;
};

/**
 * nodeに含まれるテキスト要素を結合した文字列を返す
 * @param node
 * @returns string
 */
export const getTextFromNode = (node: HTMLElement): string => {
  return Array.prototype.map
    .call(node.childNodes, (node) => {
      if (node instanceof Text) {
        return node.textContent;
      }
      if (node instanceof HTMLElement) {
        return node.innerText;
      }
    })
    .join('');
};

/**
 * web-viewer を構成する Node から自分を含んでいる content に対応する HTMLElement を取得する
 * TODO: HTMLElement を継承して dataset の中身が固定されている interface を返してもいいかもしれない
 * @param node
 * @returns
 */
export const getWrapperNode = (node: Node): HTMLElement | null => {
  if (node instanceof HTMLElement) {
    if (node.classList.contains('body-box-content')) return node;
  }
  if (node.parentNode) {
    return getWrapperNode(node.parentNode);
  }
  return null;
};

/**
 * ノードの位置を比較して n1 が先の場合は true を、 n2 が先の場合は false を返す
 * @param n1
 * @param n2
 */
export const compareNode = (n1: Node, n2: Node): boolean => {
  const comparison = n1.compareDocumentPosition(n2);
  return !(comparison & Node.DOCUMENT_POSITION_PRECEDING);
};
