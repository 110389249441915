<template>
  <confirm-dialog
    ref="dialog"
    v-model="pdfItemDialog"
    icon="mdi-bookmark"
    title="クイックアクセス"
    confirm-label="クイックアクセスに登録する"
    message="この内容でクイックアクセスに登録します"
    :disabled="descriptionValidator(itemDescription) !== true"
    @confirm="itemCommitHandler"
  >
    <div class="webview-dialog-body">
      <p>
        文献名 {{ record.title }}<br />
        {{ headingText }}
      </p>
    </div>
    <v-textarea v-model="itemDescription" label="メモ" outlined :rules="[descriptionValidator]" />
  </confirm-dialog>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'nuxt-property-decorator';
import ConfirmDialog from '@/components/common/confirm-dialog.vue';
import { descriptionValidator } from '@/utils/binderFoldersUtils';
import { DocRecord, QuickAccessItem } from 'wklr-backend-sdk/models';
import { isAccessible } from '@/utils/documentUtils';

@Component({
  components: { ConfirmDialog },
})
export default class AddQuickAccessPdfItemDialog extends Vue {
  $refs!: {
    dialog: ConfirmDialog;
  };

  descriptionValidator = descriptionValidator;

  @Prop()
  record!: DocRecord;

  @Prop()
  success?: (response: QuickAccessItem) => void;

  selectedPageSeq: number | null = null;
  get pdfItemDialog(): boolean {
    return this.selectedPageSeq !== null;
  }
  set pdfItemDialog(value) {
    if (!value) {
      this.selectedPageSeq = null;
    } else {
      console.warn('set selectedPageSeq to activate bookmarkDialog');
    }
  }

  get headingText(): string {
    if (this.selectedPageSeq !== null) {
      if (isAccessible(this.record) && this.record.folioPerSeq && this.record.folioPerSeq[this.selectedPageSeq]) {
        return `${this.record.folioPerSeq[this.selectedPageSeq]} ページ`;
      } else {
        return `${this.selectedPageSeq + 1} ページ`;
      }
    }

    return '';
  }

  itemDescription = '';

  async itemCommitHandler() {
    if (this.selectedPageSeq === null) {
      this.pdfItemDialog = false;
      return;
    }

    try {
      const response = await this.$repositories.quickAccess.createPdfItem(
        this.record.id,
        this.selectedPageSeq,
        this.headingText,
        // TODO: /api/v2/documents/{id} のレスポンスに docDataUpdatedAt が実装されたらそれを使うように置き換える
        new Date(this.record.publishedOn).getTime() / 1000,
        this.itemDescription,
      );

      this.$toast.success('クイックアクセスに登録しました');
      this.pdfItemDialog = false;
      if (this.success) {
        this.success(response);
      }
    } catch (error) {
      this.$refs.dialog.reactivate();
      console.error(error);
      this.$toast.error('クイックアクセスへの登録に失敗しました');
    }
  }

  async show(pageSeq: number) {
    this.selectedPageSeq = pageSeq;
    this.itemDescription = '';
  }
}
</script>
