<template>
  <div
    ref="body-content-wrapper"
    class="flash-wrapper"
    :class="{ '-flash': flash, '-hide': hide, '-emphasized': emphasized }"
    :style="{ height: height }"
    :data-page-seq="content.topPageSeq"
    :data-parent-key="content.parent"
    :data-series="content.series"
  >
    <div class="body-content-action-area" :class="{ '-sectionBottom': content.lastContent }">
      <share-fragment-button
        :is-first-content="content.firstContent"
        :section-key="content.parent"
        :content-series="content.series"
        :record="record"
        @mouseenter="outline = true"
        @mouseleave="outline = false"
      />
    </div>
    <template v-if="isContentVisible">
      <div class="box-content" :class="{ '-outline': outline, '-sectionBottom': content.lastContent }">
        <body-content-text
          :bookmarks="bookmarks"
          :quick-access-items="quickAccessItems"
          :content="bodyTextContent"
          :depth="content.depth"
          :parent="content.parent"
          :series="content.series"
          :type="record.type"
          :is-section-heading="bodyTextContent.type !== 'figure' && content.firstContent"
          :folio-page-seq="topFolioPageSeq"
          :disable-bookmark="disableBookmark"
          :is-dev="$nuxt.context.isDev"
          @mouseover="mouseOverHandler"
          @mouseout="mouseOutHandler"
          @click="mouseClickHandler"
          @click-quickaccess="$emit('click-quickaccess', content.parent)"
          @click-binder="$emit('click-binder', content.parent)"
        />
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch, State } from 'nuxt-property-decorator';
import BodyContentText from '@/components/viewer/body-content-text.vue';
import ShareFragmentButton from '@/components/viewer/share-fragment-button.vue';
import { processText } from '@/utils/bodyContentUtils';
import { State as MyState } from '@/store';
import { ContentTextTypeFigure, ContentTextTypeText, SectionContentEx } from '@/types/web-viewer';
import { Nullable } from '@/types/nullable';
import { BookmarkTypeWebWithBinderDetail } from './web-viewer';
import { HighlightedContentWithColor } from '@/types/binder-folders';
import { DocRecord, QuickAccessItemTypeWeb, TextContentTypeEnum } from 'wklr-backend-sdk/models';
import { isAccessible } from '@/utils/documentUtils';
import NO_IMAGE from '@/assets/noimage.png';

@Component({ components: { BodyContentText, ShareFragmentButton } })
export default class BodyContent extends Vue {
  $refs!: {
    'body-content-wrapper': HTMLElement;
    [key: string]: Vue | Element | Vue[] | Element[];
  };

  @Prop() content!: SectionContentEx;
  @Prop() emphasized!: boolean;
  @Prop() flash!: boolean;
  @Prop() bookmarks!: BookmarkTypeWebWithBinderDetail[];
  @Prop() quickAccessItems!: QuickAccessItemTypeWeb[];
  @Prop() highlights!: HighlightedContentWithColor[];
  @Prop() record!: DocRecord;
  @Prop() currentSeries!: number;
  @Prop() isPreview!: boolean;
  @Prop() disableBookmark!: boolean;

  /** 検索ワードとして強調表示すべきキーワード */
  @State((state: MyState) => state.document.find.keywords) keywords!: string[];

  // /** 検索ワードとして強調表示すべきキーワード */
  @State((state: MyState) => state.document.find.hit.currentSeries) searchCurrentSeries!: number | null;

  /** アウトラインを表示するかどうかのフラグ。 share-fragment にホバーした時にシェア対象を視覚的に分かるように表示するために利用する */
  outline = false;

  /** 画面外として扱うかどうかのフラグ。 intersectionObserver によるコールバック後高さを指定してから有効にするために State にしている */
  isOutScreen = false;

  /** intersectionObserver プラグインを利用するためのフラグ */
  isInview = false;
  /** intersectionObserver プラグインを利用するためのID */
  intersectionObserverRefId?: string;

  /** type = figure だった時に利用される URL 文字列の保存用 */
  figureImageUrl: string | null = null;

  /** type = figure だった時に利用されるローディング状態管理用のフラグ */
  figureImageIsLoading = false;

  /** 画像の取得に失敗した時の画像（ URL が入る） */
  readonly noImage = NO_IMAGE;

  /** テキストコンテントの中身の種別を判定する */
  get contentType(): 'text' | 'figure' {
    // FIXME: 本来であれば API から type を返して欲しい
    return this.content.type === TextContentTypeEnum.Text && this.content.hasFigureInfo ? 'figure' : 'text';
  }

  /** セクション先頭の PDF におけるページ番号を取得する。対応（ folioPageSeq が存在しない場合は null を返して何も表示しない） */
  get topFolioPageSeq(): Nullable<string> {
    if (!isAccessible(this.record)) return null;
    if (this.record.folioPerSeq) {
      const folio = this.record.folioPerSeq[this.content.topPageSeq];
      return isNaN(parseInt(folio)) ? folio : `P${folio}`;
    }
    return null;
  }

  get isContentVisible(): boolean {
    return !this.hide && this.content.type === TextContentTypeEnum.Text;
  }

  /** パースしてハイライトなどの適用が済んだ文字列 */
  processedText = '';

  /** processedText を更新する */
  updateProcessedText() {
    this.processedText = processText(
      this.record.id,
      this.record.type,
      this.content,
      this.highlights,
      this.keywords,
      this.isPreview,
    );
  }

  /** content.text から生成される processedText のアップデート処理の要否を判別するためのキャッシュキー。Vue.js には componentShouldUpdate が用意されていないので自前で更新をチェックする */
  contentTextCache = '';
  /** highlights から生成される processedText のアップデート処理の要否を判別するためのキャッシュキー。Vue.js には componentShouldUpdate が用意されていないので自前で更新をチェックする */
  highlightsCache = '';

  /** コンテンツ種別とハイライトに関しては以前と差分がない場合は更新しない */
  @Watch('contentType')
  @Watch('highlights')
  updateHandler() {
    if (this.content.type !== TextContentTypeEnum.Text) return;
    const highlightsCache = this.getHighlightsCacheKey(this.highlights);
    if (this.content.text !== this.contentTextCache || highlightsCache !== this.highlightsCache) {
      this.contentTextCache = this.content.text;
      this.highlightsCache = this.getHighlightsCacheKey(this.highlights);
      this.updateProcessedText();
    }
  }

  /** 文書内検索については常に更新する */
  @Watch('keywords')
  forceUpdateHandler() {
    this.updateProcessedText();
  }

  /** ハイライトオブジェクトの配列を文字列に変換する */
  getHighlightsCacheKey(highlights: HighlightedContentWithColor[]): string {
    return highlights.map((hl) => `${hl.series}-${hl.startPosition}-${hl.endPosition}-${hl.color}`).join(',');
  }

  get bodyTextContent(): ContentTextTypeText | ContentTextTypeFigure {
    if (this.content.type !== TextContentTypeEnum.Text)
      throw new Error('content の種別が不正です（ text しか利用できません）');

    switch (this.contentType) {
      case 'text':
        return {
          type: 'text',
          processedText: this.processedText,
        };
      case 'figure': {
        // 本当は構造化されたデータから取得したいが、fetchFigureでも似たようなことをやっているので一旦許容する。
        const captionMatch = this.content.text.match(/<figcaption>(.*?)<\/figcaption>/);
        const caption = captionMatch ? captionMatch[1] : undefined;

        return {
          type: 'figure',
          isLoading: this.figureImageIsLoading,
          imageUrl: this.figureImageUrl,
          caption,
        };
      }
    }

    throw new Error('contentTypeの種別が不正です');
  }

  get hide(): boolean {
    const sidenoteSearchRange = 30;
    return (
      this.isOutScreen &&
      // TEMP: 注釈がありそうな範囲はDOMを表示したままにしておく
      !(this.currentSeries <= this.content.series && this.content.series <= this.currentSeries + sidenoteSearchRange) &&
      // 検索結果は表示したままにしておく
      !(this.searchCurrentSeries === this.content.series)
    );
  }

  get height(): string {
    return this.hide ? `${this.renderedHeight}px` : 'auto';
  }
  renderedHeight: number | null = null;

  mounted() {
    try {
      this.intersectionObserverRefId = this.$registerIntersectionObserver(this.$refs['body-content-wrapper'], this);
    } catch (error) {
      this.isInview = true;
      console.warn(error);
    }

    if (this.contentType === 'figure') {
      this.fetchFigure();
    }

    this.updateProcessedText();
  }

  beforeDestroy() {
    if (this.intersectionObserverRefId) {
      this.$resignIntersectionObserver(this.intersectionObserverRefId);
    }
  }

  @Watch('isInview')
  inViewHandler() {
    if (this.content.type !== TextContentTypeEnum.Text) return;
    this.$emit('inview', this.isInview);
    if (!this.isInview) {
      this.$nextTick(() => {
        this.renderedHeight = this.$refs['body-content-wrapper'].offsetHeight;
        this.isOutScreen = true;
      });
    } else {
      this.isOutScreen = false;
    }
  }

  async fetchFigure() {
    this.figureImageIsLoading = true;
    try {
      if (this.content.type !== TextContentTypeEnum.Text) {
        throw new Error('content type が text でないのに figure 画像を取得しようとしています');
      }

      const srcText = this.content.text;
      const match = srcText.match(/<img src="\/api\/.+\/documents\/(.+?)\/figures\/(.+?)"/);
      if (match) {
        const [_, docID, figureID] = match;
        const response = await this.$repositories.figures.getFigures(docID, figureID);
        this.figureImageUrl = response.url;
      }
    } catch (error) {
      console.error(error);
      this.$toast.error('画像の読み込みに失敗しました');
      this.figureImageUrl = this.noImage;
    } finally {
      this.figureImageIsLoading = false;
    }
  }

  mouseOverHandler(target: HTMLElement, event: MouseEvent) {
    this.$emit('mouseover', target, event);
  }
  mouseOutHandler(target: HTMLElement, event: MouseEvent) {
    this.$emit('mouseout', target, event);
  }
  mouseClickHandler(target: HTMLElement, event: MouseEvent) {
    this.$emit('click', target, event);
  }
}
</script>

<style lang="scss" src="./body-content.scss" scoped />
