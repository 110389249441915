<template>
  <div :class="{ 'body-content-text': true, 'section-heading': isSectionHeading }">
    <template v-if="!disableBookmark && isSectionHeading">
      <bookmark-status
        :bookmarks="bookmarks"
        :quick-access-items="quickAccessItems"
        @click-quickaccess="$emit('click-quickaccess')"
        @click-binder="$emit('click-binder')"
      />
    </template>

    <div
      class="body-box-content"
      :class="bodyContentClass"
      :title="isDev ? `key = ${parent}, series = ${series}` : ''"
      :data-parent-key="parent"
      :data-series="series"
      :data-section-type="content.type"
      @mouseover="mouseOverHandler"
      @mouseout="mouseOutHandler"
      @click.prevent="mouseClickHandler"
    >
      <!-- eslint-disable-next-line vue/no-v-html -->
      <div v-if="content.type === 'text'" v-html="content.processedText" />
      <div v-if="content.type === 'figure' && !content.caption">
        <img :src="content.isLoading ? loadingImage : content.imageUrl || undefined" loading="lazy" />
      </div>
      <div v-if="content.type === 'figure' && content.caption">
        <figure class="with-img">
          <img :src="content.isLoading ? loadingImage : content.imageUrl || undefined" loading="lazy" />
          <figcaption v-html="content.caption"></figcaption>
        </figure>
      </div>
    </div>

    <template v-if="isSectionHeading">
      <div v-if="shouldShowBorder" class="border" />
      <div v-if="isNotLaw && folioPageSeq" class="page">{{ folioPageSeq }}</div>
    </template>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import BookmarkStatus from '@/components/viewer/bookmark-status.vue';
import { Nullable } from '@/types/nullable';
import { BookmarkTypeWebWithBinderDetail } from './web-viewer';
import { DocumentTypeEnum, QuickAccessItemTypeWeb } from 'wklr-backend-sdk/models';
import { ContentTextTypeFigure, ContentTextTypeText } from '@/types/web-viewer';
import LOADING_IMAGE from '@/assets/loading.png';

@Component({ components: { BookmarkStatus } })
export default class BodyContentText extends Vue {
  @Prop()
  content!: ContentTextTypeText | ContentTextTypeFigure;

  @Prop()
  depth!: number;

  @Prop()
  parent!: number;

  @Prop()
  series!: number;

  @Prop()
  isSectionHeading!: boolean;

  @Prop()
  bookmarks!: BookmarkTypeWebWithBinderDetail[];

  @Prop()
  quickAccessItems!: QuickAccessItemTypeWeb[];

  @Prop()
  folioPageSeq!: Nullable<string>;

  @Prop()
  type!: DocumentTypeEnum;

  @Prop()
  disableBookmark!: boolean;

  @Prop()
  isDev!: boolean;

  /** ローディング画像 (URLが入る) */
  readonly loadingImage = LOADING_IMAGE;

  get bodyContentClass(): string[] {
    return [`depth-${this.depth}`, this.type === DocumentTypeEnum.Law ? '-law' : '-default'];
  }

  get isNotLaw(): boolean {
    return this.type !== DocumentTypeEnum.Law;
  }

  /** 前文または制定文ではないかを返す */
  get isNotPreambleOrEnactingClause(): boolean {
    return this.folioPageSeq !== '前文' && this.folioPageSeq !== '制定文';
  }

  get shouldShowBorder(): boolean {
    return this.isNotLaw || this.isNotPreambleOrEnactingClause;
  }

  getClickContentEventTarget = (target: EventTarget | null): HTMLElement | null => {
    if (!(target instanceof HTMLElement)) return null;
    let ret: HTMLElement | null = target;
    while (ret !== null && ret !== this.$el) {
      if (['A', 'SUP'].includes(ret.tagName)) return ret;
      ret = ret.parentElement;
    }
    return ret;
  };

  mouseOverHandler(event: MouseEvent) {
    const target = this.getClickContentEventTarget(event.target);
    if (target) {
      console.log('OVER', target, event);
      this.$emit('mouseover', target, event);
    }
  }

  mouseOutHandler(event: MouseEvent) {
    const target = this.getClickContentEventTarget(event.target);
    if (target) {
      console.log('OUT', target, event);
      this.$emit('mouseout', target, event);
    }
  }
  mouseClickHandler(event: MouseEvent) {
    const target = this.getClickContentEventTarget(event.target);
    if (target) {
      console.log('CLICK', target, event);
      this.$emit('click', target, event);
    }
  }
}
</script>

<style lang="scss">
.body-content-text {
  width: 100%;
}

.body-box-content {
  // `TextContent` から渡される HTML に適用するスタイル
  @import '@/components/viewer/text-content-style/import.scss';

  // 文献内検索ハイライト
  // FIXME: ハイライトでは無いので名前を訂正する
  .highlight {
    border-bottom: 0px solid $legalscape-navy;
    background: hsl(60, 100%, 80%);
    color: black;
    transition: border-bottom-width 0.2s ease-in-out;

    &.highlight-current {
      border-bottom: 3px solid $legalscape-navy;
    }

    @for $i from 1 through 18 {
      &.highlight-#{$i} {
        background: hsl(($i * 85 + 60) % 360, 100%, 80%);
      }
    }
  }

  a {
    // Legalscape 内部リンク

    &.internal-reference,
    /* FALLBACK */ &[href^='/document'] {
      // No Style
    }

    // 委任法令
    &.delegation-reference {
      &[data-delegation-type='law'] {
        color: #555;
        text-decoration: underline dotted lighten(#555, 30%);
        text-underline-offset: 4px;
        text-decoration-thickness: 2px;

        &:hover {
          color: #333;
          text-decoration-color: #333;
        }
      }
    }

    // 外部リンク
    &.external-reference,
    &[data-search-query][href]:not([href='#unavailable']),
    &[href^='http'],
    // &:not([href^='/']),
    &[data-reference-label] {
      color: #555;
      text-decoration: underline dotted lighten($primary, 30%);
      text-underline-offset: 4px;
      text-decoration-thickness: 2px;

      &:hover {
        color: #333;
        text-decoration-color: $primary;
      }
    }

    // 非掲載文献リンク
    &[href='#unavailable'],
    &.unavailable-reference,
    &[data-search-query]:not([href]):not(.external-reference) {
      color: #777;
      cursor: not-allowed;
      text-decoration: underline dotted lighten($unavailable, 30%);
      text-underline-offset: 4px;
      text-decoration-thickness: 2px;

      &:hover {
        color: #555;
        text-decoration-color: $unavailable;
      }
    }
  }
}

.section-heading {
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  > .border {
    flex-grow: 1;
    height: 1px;
    background: #efefef;
    margin: 0 8px;
    min-width: 65px;
  }

  > .page {
    font-family: 'Noto Sans JP', sans-serif;
    font-size: 14px;
    font-weight: bold;
    color: $primary;
  }
}
</style>
