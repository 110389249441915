import { Component, Vue, Prop } from 'nuxt-property-decorator';
import DocumentListItem from '@/components/document-list-item.vue';
import * as constants from '@/constants';
import { DocRecord, DocumentTypeEnum, GetMeResponse, GetPerDocumentQuotaResponse } from 'wklr-backend-sdk/models';
import { isAccessible } from '@/utils/documentUtils';
const { viewerUrl } = constants.Document.PdfViewer;

@Component({ components: { DocumentListItem } })
export default class PrintDialog extends Vue {
  /** PDFView 内で選択されているページの一覧を取得する */
  @Prop() listSelectedPages!: () => { total: number; selected: number[] };

  /** 印刷対象の文書 */
  target: DocRecord | null = null;

  /** ダイアログ自体の表示 */
  dialog = false;

  /** 奥付の追加 */
  addColophon = false;

  /** 選択されたページ */
  pagesToPrint: number[] = [];

  /** 印刷可能な残りページ数 */
  remainingQuota = 0;

  /** 購入済みかどうか */
  isPurchased = false;

  get isBook(): boolean {
    return this.target !== null && this.target.type === DocumentTypeEnum.Book;
  }

  /** ダイアログを表示する */
  async show(target: DocRecord, user: GetMeResponse) {
    this.target = target;

    const selectedPages = this.listSelectedPages();
    const totalPages = selectedPages.total;

    this.pagesToPrint = selectedPages.selected;

    if (this.pagesToPrint.length < 1) {
      this.$toast.error('印刷するページが選択されていません。');
      return;
    }

    // 書籍の場合のみ、細かな制御を入れる
    if (this.isBook) {
      let pdqResponse: GetPerDocumentQuotaResponse;
      try {
        pdqResponse = await this.$repositories.users.getPerDocumentQuota(target.id);
      } catch (e) {
        this.$toast.error('申し訳ありません。印刷エラーが発生しました。しばらくしてからもう一度印刷をお試しください。');
        return;
      }
      const {
        quota: { print: printQuota },
        isPurchased,
      } = pdqResponse;
      this.isPurchased = isPurchased;

      if (this.isPurchased) {
        if (this.pagesToPrint.length > totalPages / 4) {
          this.$toast.error('書籍の半分以上のページが選択されています。書籍の1/4以上を印刷することはできません。');
          return;
        }
      } else {
        if (this.pagesToPrint.length > totalPages / 2) {
          this.$toast.error('書籍の半分以上のページが選択されています。書籍の半分以上を印刷することはできません。');
          return;
        }
      }

      if (!isAccessible(target)) {
        this.$toast.error('この書籍は印刷できません');
        return;
      }

      const numPagesToConsumeQuota =
        typeof target.colophonOn === 'number' && this.pagesToPrint.includes(target.colophonOn)
          ? this.pagesToPrint.length - 1
          : this.pagesToPrint.length;

      const { numPrinted, allocatedPages } = printQuota;
      this.remainingQuota = allocatedPages - numPrinted;

      if (this.remainingQuota === 0) {
        this.$toast.error(
          this.isPurchased
            ? `この書籍につき過去180日間で印刷したページ数が印刷可能ページ数 (${allocatedPages} ページ) に達しています。現在はこれ以上印刷できません。`
            : `今月印刷したページ数が月間の印刷可能ページ数 (${allocatedPages} ページ) に達しています。今月はこれ以上印刷できません。`,
        );
        return;
      }
      if (numPagesToConsumeQuota > this.remainingQuota) {
        this.$toast.error(
          `選択されたページ数 (${numPagesToConsumeQuota} ページ) が${
            this.isPurchased ? '現在' : '今月の'
          }印刷可能な残りページ数 (${
            this.remainingQuota
          } ページ) を超過しています。ページ数を減らしてから再度印刷を試みてください。`,
        );
        return;
      }
    }

    this.dialog = true;
  }

  /** 印刷開始 */
  async print() {
    if (!this.target || this.pagesToPrint.length === 0) {
      return;
    }

    const pageSeqs = encodeURIComponent(JSON.stringify(this.pagesToPrint));
    window.open(
      `${location.protocol}//${location.host}/${viewerUrl}&file=${encodeURIComponent(
        `${process.env.API_URL_FROM_CLIENT_SIDE}/documents/${
          this.target.id
        }/extract-pdf?pageSeqs=${pageSeqs}&withColophon=${this.addColophon === true ? 1 : 0}`,
      )}#print=1`,
    );

    this.$telemetry.sendExportTelemetry(
      {
        action: 'print',
        pageSeqs: this.pagesToPrint,
        withColophon: this.addColophon,
      },
      this.$route,
    );

    this.dialog = false;
    this.pagesToPrint = [];
    this.addColophon = false;
    this.$toast.success('印刷を開始しました。');
  }
}
