<template>
  <div
    ref="body-content-wrapper"
    class="flash-wrapper"
    :class="{ '-flash': flash, '-hide': hide }"
    :style="{ height: height }"
  >
    <!-- 初期ローディング -->
    <div v-if="!hide" class="mx-2 px-2 box-content box-content-loading" :class="emphasized ? '-emphasized' : ''">
      <div class="body-box-content px-4">
        <div class="text-center my-12 py-12">
          <v-progress-circular v-if="content.state === 'loading'" indeterminate color="grey" />
        </div>
      </div>
    </div>
    <!-- /初期ローディング -->
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'nuxt-property-decorator';
import { WebviewerSection } from '@/utils/webViewerUtils';
import NO_IMAGE from '@/assets/noimage.png';

@Component
export default class BodyContentLoading extends Vue {
  $refs!: {
    'body-content-wrapper': HTMLElement;
    [key: string]: Vue | Element | Vue[] | Element[];
  };

  @Prop() content!: WebviewerSection;
  @Prop() emphasized!: boolean;
  @Prop() flash!: boolean;

  hide = false;

  isInview = false;
  intersectionObserverRefId?: string;

  figureImageUrl: string | null = null;
  figureImageIsLoading = false;

  /** 画像の取得に失敗した時の画像（ URL が入る） */
  readonly noImage = NO_IMAGE;

  get height(): string {
    return this.hide ? `${this.renderedHeight}px` : 'auto';
  }
  renderedHeight: number | null = null;

  mounted() {
    try {
      this.intersectionObserverRefId = this.$registerIntersectionObserver(this.$refs['body-content-wrapper'], this);
    } catch (error) {
      this.isInview = true;
      console.warn(error);
    }
  }

  beforeDestroy() {
    if (this.intersectionObserverRefId) {
      this.$resignIntersectionObserver(this.intersectionObserverRefId);
    }
  }

  @Watch('isInview')
  inViewHandler() {
    this.$emit('inview', this.isInview);
    if (!this.isInview) {
      this.$nextTick(() => {
        this.renderedHeight = this.renderedHeight = this.$refs['body-content-wrapper'].offsetHeight;
        this.hide = true;
      });
    } else {
      this.hide = false;
    }
  }
}
</script>

<style lang="scss" src="./body-content.scss" scoped />
