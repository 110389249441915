import { escapeRegExp } from '@/utility';
import {
  REPLACER_TAG_END_CHAR_CODE,
  REPLACER_TAG_START_CHAR_CODE,
  REPLACER_TAG_END_UNICODE,
  REPLACER_TAG_START_UNICODE_RANGE,
  REPLACER_TAG_UNICODE_ALL_RANGE,
  REPLACER_AMPERSAND_CHAR_CODE,
  REPLACER_LESS_THAN_CHAR_CODE,
  REPLACER_GREATER_THAN_CHAR_CODE,
} from '@/constants';
import { highlightColorName } from './binderFoldersUtils';
import { HighlightedContentWithColor } from '@/types/binder-folders';
import { SectionContentEx } from '@/types/web-viewer';
import { TextContentTypeEnum, DocumentTypeEnum } from 'wklr-backend-sdk/models';

// TODO: どこかに持っていく & Store に入れるタイミングでサニタイズしたい
function sanitizeKeyword(keyword: string): string | null {
  if (/^".+"$/.test(keyword)) {
    // ダブルクォートを外す
    keyword = keyword.slice(1, -1);
  }

  {
    // orはハイライトしない
    const keywordLower = keyword.toLowerCase();
    if (keywordLower === 'or' || keywordLower === 'ｏｒ') {
      return null;
    }
  }

  // FIXME: poorman's hack for HTML entity notations
  keyword = keyword.replace(/&/g, '&amp;');

  return keyword;
}

/**
 * ハイライトがオーバーラップしているとタグの構造も不正になってしまうので、重複部分が無いようにする
 *
 * todo startPosition も endPosition も完全に一致するハイライトが複数存在した場合、何らかのルールに従って特定のハイライトだけを残す処理が必要となる (例えば最近追加・更新されたハイライトを生かす、など)
 */
export function trimOverlap(highlights: HighlightedContentWithColor[]): HighlightedContentWithColor[] {
  if (highlights.length <= 1) {
    return highlights;
  }

  // startPosition の昇順に並べる (startPosition が同一の場合は、endPosition が遠いほうが先に来るように並べる)
  const sorted = highlights.sort((l, r) => {
    if (l.startPosition !== r.startPosition) {
      return l.startPosition - r.startPosition;
    }
    return r.endPosition - l.endPosition;
  });

  // Web ビューに反映させるハイライト領域がまだ確定していないハイライトを、このスタックに積んで管理する
  const stack: HighlightedContentWithColor[] = [];
  let top: HighlightedContentWithColor | undefined;

  // この位置より左側の部分はすでにハイライトが確定している
  let lastEndPosition: number = sorted[0].startPosition;

  const result: HighlightedContentWithColor[] = [];

  // startPosition の順にハイライトを走査し、ハイライト領域を確定していく
  for (const highlight of sorted) {
    // スタックに積まれている未確定部分が残るハイライトを確定していく
    while (stack.length > 0 && (top = stack.pop()) !== undefined) {
      if (lastEndPosition < highlight.startPosition && top.endPosition > lastEndPosition) {
        // Web ビューに表示できるハイライト領域が一文字でも残っていればそれを出力しておく

        const { startPosition, endPosition, ...rest } = top;
        const newStartPosition = Math.max(lastEndPosition, startPosition);
        lastEndPosition = Math.min(endPosition, highlight.startPosition);

        result.push({
          ...rest,
          startPosition: newStartPosition,
          endPosition: lastEndPosition,
        });
      }

      if (top.endPosition > highlight.endPosition) {
        // 未確定の領域が残るハイライトに到達したところでスタックに積み直してループを抜ける
        stack.push(top);
        break;
      }
    }

    // この後に続くハイライトが判明しない限り、このイテレーションで着目しているハイライトの領域が確定しないのでスタックに積む
    stack.push(highlight);
  }

  // スタックに積み残しているハイライトを確定していく
  while (stack.length > 0 && (top = stack.pop()) !== undefined) {
    if (top.endPosition > lastEndPosition) {
      const { startPosition, ...rest } = top;

      result.push({
        ...rest,
        startPosition: Math.max(lastEndPosition, startPosition),
      });

      lastEndPosition = top.endPosition;
    }
  }

  return result;
}

/**
 * HTMLが含まれるテキストの処理のために、テキストノードとタグの配列に変換する
 * 0, 2, 4... と偶数がテキスト、 1, 3, 5,... と奇数がタグ
 */
export function intoSegment(text: string): string[] {
  return text.split(/(<[^>]*>)/);
}

const REGEXP_REPLACER_AMPERSAND = new RegExp(String.fromCodePoint(REPLACER_AMPERSAND_CHAR_CODE), 'g');
const REGEXP_REPLACER_LESS_THAN = new RegExp(String.fromCodePoint(REPLACER_LESS_THAN_CHAR_CODE), 'g');
const REGEXP_REPLACER_GREATER_THAN = new RegExp(String.fromCodePoint(REPLACER_GREATER_THAN_CHAR_CODE), 'g');

// FIXME: 重なり合うハイライトの取り扱いができないのでなおさないといけない。なおす場合は、ハイライトごとに文字色が alpha: 0 で該当部分がハイライトになっている div をハイライト分だけ重ねるとかになる
export function insertHighlights(text: string, highlights: HighlightedContentWithColor[]): string {
  if (highlights.length === 0) {
    // ハイライトが存在しないならセグメントに分割する手間が無駄になるので、ここでお引き取り願う
    return text;
  }

  highlights = trimOverlap(highlights);
  let highlightIndex = 0;

  let textCurPos = 0;
  return intoSegment(text)
    .map((segment, i) => {
      if (i % 2 !== 0) {
        return segment;
      }
      if (highlightIndex >= highlights.length) {
        // ハイライトをすべて反映し終えていれば、後の処理はしなくていい (セグメントのテキストをそのまま返してよい)
        return segment;
      }
      if (segment.length === 0) {
        // 空文字列を処理してはならない (ハイライト反映がおかしくなる原因になりうるので)
        return segment;
      }

      // 実体参照のままだと文字数の計算にズレが発生するので、一度別の文字で置き換える
      // (パターンを | で繋いで一回の replace() で置換を済ませるよりも、個別のパターンごとに replace() をした方が速いっぽい)
      segment = segment
        .replace(/&amp;/g, String.fromCodePoint(REPLACER_AMPERSAND_CHAR_CODE))
        .replace(/&lt;/g, String.fromCodePoint(REPLACER_LESS_THAN_CHAR_CODE))
        .replace(/&gt;/g, String.fromCodePoint(REPLACER_GREATER_THAN_CHAR_CODE));

      const segmentStartPos = textCurPos;
      const segmentEndPos = textCurPos + segment.length;
      let highlightedSegment = '';

      // startPosition の順に並んだハイライトを **前回の続きから** 読み進める
      for (
        ;
        highlightIndex < highlights.length && highlights[highlightIndex].startPosition < segmentEndPos;
        highlightIndex += 1
      ) {
        const highlight = highlights[highlightIndex];

        if (highlight.startPosition > textCurPos) {
          // ハイライト開始位置より左側に存在する、ハイライトが掛かっていないテキストを付け加える
          highlightedSegment += segment.slice(textCurPos - segmentStartPos, highlight.startPosition - segmentStartPos);
          textCurPos = highlight.startPosition;
        }

        const sliceStartPos = textCurPos - segmentStartPos;
        const sliceEndPos = Math.min(highlight.endPosition, segmentEndPos) - segmentStartPos;

        // ハイライトを反映する
        highlightedSegment += `<span class="highlight-text -${highlightColorName(highlight.color)}">${segment.slice(
          sliceStartPos,
          sliceEndPos,
        )}</span>`;
        textCurPos += sliceEndPos - sliceStartPos;

        // いま見ているハイライトがセグメント境界をまたいで右隣のセグメント以降に続く場合は、ここで打ち止めにする
        if (highlight.endPosition > segmentEndPos) {
          break;
        }
      }

      if (textCurPos < segmentEndPos) {
        // ハイライトが掛かっていないセグメント末尾のテキストが残っているので、それを付け加える
        highlightedSegment += segment.slice(textCurPos - segmentStartPos);
        textCurPos = segmentEndPos;
      }

      // 置き換えた文字を実体参照に戻す
      return highlightedSegment
        .replace(REGEXP_REPLACER_AMPERSAND, '&amp;')
        .replace(REGEXP_REPLACER_LESS_THAN, '&lt;')
        .replace(REGEXP_REPLACER_GREATER_THAN, '&gt;');
    })
    .join('');
}

type Replacer = { regExp: RegExp; label: string };
/**
 * 文書内検索のワードを強調表示する
 * FIXME: 現在の実装では強調表示の部分重複は実現できないため、バインダー機能のハイライトの開始 or 終了位置を跨ぐ検索ワードは強調されない
 */
export function insertSearchKeyword(text: string, keywords: string[]): string {
  const regExpAndReplacerPairs = keywords
    .map((keyword) => sanitizeKeyword(keyword))
    .filter((keyword): keyword is string => keyword !== null)
    .map(
      (keyword, i): Replacer => ({
        regExp: new RegExp(escapeRegExp(keyword), 'gi'),
        label:
          String.fromCodePoint(REPLACER_TAG_START_CHAR_CODE + i) +
          '$&' +
          String.fromCodePoint(REPLACER_TAG_END_CHAR_CODE),
      }),
    );
  return intoSegment(text)
    .map((segment, i) => {
      if (i % 2 === 0) {
        segment = segment.replace(/&/g, '&amp;').replace(/>/g, '&gt;').replace(/</g, '&lt;');

        // 偶数個目：テキスト部分なので強調する
        regExpAndReplacerPairs.forEach((replacer) => {
          // 一回ダミー文字列に置き換える（一斉に置換することでタグを壊さないようにするため）
          segment = segment.replace(replacer.regExp, replacer.label);
        });

        // ダミー文字列を置き換える
        segment = segment.replace(
          new RegExp(
            `([${REPLACER_TAG_START_UNICODE_RANGE}])([^[${REPLACER_TAG_UNICODE_ALL_RANGE}]*)${REPLACER_TAG_END_UNICODE}`,
            'g',
          ),
          (_, open, keyword) =>
            `<span class="highlight highlight-${
              open.codePointAt(0)! - REPLACER_TAG_START_CHAR_CODE
            }">${keyword}</span>`,
        );

        segment = segment.replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&amp;/g, '&');
      }

      return segment;
    })
    .join('');
}

export const replaceDelegationLaw = (sourceText: string, docId: string, parentKey: number) =>
  sourceText.replace(/(規定する)?((政令|内閣府令)で定める)/g, (match, firstGroup) => {
    if (firstGroup !== undefined) return match;
    return `<a class="delegation-reference" data-delegation-type="law" data-delegation-doc-id="${docId}" data-delegation-key="${parentKey}" data-delegation-query="${match}">${match}</a>`;
  });

export const processText = (
  docId: string,
  docType: DocumentTypeEnum,
  content: SectionContentEx,
  highlights: HighlightedContentWithColor[],
  keywords: string[],
  isPreview: boolean,
): string => {
  if (content.type !== TextContentTypeEnum.Text) return '';

  let seq = content.topPageSeq;

  // 未加工の文字列
  const original = content.text;

  // バインダー機能のハイライトを適用した文字列
  const withHighlights = insertHighlights(original, highlights);

  // 検索キーワードを強調した文字列
  const withSearchKeyword = insertSearchKeyword(withHighlights, keywords);

  // 逆引きリンクを付与
  let withDelegationLink = withSearchKeyword;
  if (!isPreview && docType === DocumentTypeEnum.Law) {
    withDelegationLink = replaceDelegationLaw(withDelegationLink, docId, content.parent);
  }

  // 表示用に最終処理を施した文字列
  const processedText = intoSegment(withDelegationLink)
    .map((segment, i) => {
      if (i % 2 === 0) {
        if (segment.length > 0) {
          // TODO: web-viewer コンポーネントでも計算している。そもそもデータの中身を見ないと取れない値をシーケンスIDにするのがよくないのでなんとか直したい（でも外で使われているので変えられない）
          process.env.NODE_ENV === 'development'
            ? (segment = `<span data-seq="${seq}" title="seq = ${seq}">${segment}</span>`)
            : (segment = `<span data-seq="${seq}">${segment}</span>`);
        }
      } else if (segment.substr(0, 3) === '<br') {
        // <br>タグごとに改ページ
        ++seq;
      } else {
        // 奇数個目：HTMLタグ（の開きタグか閉じタグ）なのでサニタイジング
        segment = segment.replace(/<(\/?)script/gi, '&lt;$1script');
        segment = segment.replace(/<([a-z0-9-]+)\s+on/gi, '<$1 no');
      }
      return segment;
    })
    .join('');

  return processedText;
};
