/**
 * フラットな配列を pageSeq をキーとしたハッシュマップにマップし直す
 * @param arr
 * @returns
 */
export const mapByPageSeq = <T extends { pageSeq: number }>(arr: T[]): { [pageSeq: number]: T[] } => {
  const result: { [pageSeq: number]: T[] } = {};
  arr.forEach((item) => {
    if (Array.isArray(result[item.pageSeq])) {
      result[item.pageSeq].push(item);
    } else {
      result[item.pageSeq] = [item];
    }
  });
  return result;
};
