import { Component, Vue, State } from 'nuxt-property-decorator';
import { DocumentConfig } from '@/types/document-config';

@Component
export default class DocumentConfigDialog extends Vue {
  /** 閲覧画面の設定 */
  @State((state) => state.persistent.documentConfig) config!: DocumentConfig;

  /** v-model */
  dialog = false;

  updateBodyFontSize(bodyFontSize: DocumentConfig['bodyFontSize']): void {
    this.$store.commit('setBodyFontSize', bodyFontSize);
  }

  updateMaxWidth(maxWidth: DocumentConfig['maxWidth']): void {
    this.$store.commit('setMaxWidth', maxWidth);
  }
}
