<template>
  <span class="keyword-tag">
    <span class="inner">
      <span>{{ word }}</span>
      <v-btn icon @click="$emit('remove')"><v-icon size="11">mdi-close</v-icon></v-btn>
    </span>
  </span>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'nuxt-property-decorator';

@Component
export default class KeywordTag extends Vue {
  @Prop() word!: string;
}
</script>

<style lang="scss" scoped>
.keyword-tag {
  padding: 2px 0; // align-self: center; にすると縦位置がずれるので padding でサイズ調整する

  > .inner {
    background: white;
    display: flex;
    align-items: center;
    border: 1px solid #888;
    border-radius: 3px;
    padding: 2px 0 2px 4px;
    font-size: 11px;
    white-space: nowrap;
    margin-right: 4px;

    ::v-deep .v-btn--icon {
      width: 16px;
      height: 16px;
    }
  }
}
</style>
