import { ContextMenuItem } from '@/components/shared/context-menu';
import { LawCitations, WithReferenceContentsContentsInner } from 'wklr-backend-sdk/models';
import { Repositories } from '@/plugins/repositories';
import { removeTags } from '@/utility';
import { generateReferenceLocation } from '@/utils/urlUtils';

const flattenCandidates = (candidates: LawCitations[]): [LawCitations, WithReferenceContentsContentsInner][] => {
  const result: [LawCitations, WithReferenceContentsContentsInner][] = [];

  /*
  APIは同じパラグラフに同じ文書を参照している箇所が2箇所以上ある場合、それらはまとめず別のlinkとして返す。
  そのような状況の場合、candidatesには同じ文書の同じセクションが複数回登場することになり、ユーザーには同じ項目が2つ並んで見えてしまう。
  ここでその重複を排除する。
  */
  candidates.forEach((law) => {
    const series = new Set<number>();
    (law.contents || []).forEach((link) => {
      if (!series.has(link.series)) {
        result.push([law, link]);
        series.add(link.series);
      }
    });
  });
  return result;
};
export const loadCandidates =
  (
    repositories: Repositories,
    docId: string,
    sectionKeys: number[],
    sectionNumber: string,
    linkText: string,
  ): (() => Promise<{ header: string; items: ContextMenuItem[] }[]>) =>
  async () => {
    const laws = await repositories.citations.getCitationLaw(docId, sectionKeys);
    let regexp: RegExp;
    if (/第.*?項/.test(sectionNumber)) {
      regexp = new RegExp(`${sectionNumber}([(（].*[)）])?に規定する${linkText}`, 'ig');
    } else {
      // 第一項を補完する。号が存在していればその直前に挿入
      let complementedSectionKey: string = sectionNumber.replace(/(第.*?条.*?)(第.*?号)/gi, '$1第一項$2');
      if (sectionNumber === complementedSectionKey) {
        // 存在しない場合は末尾に結合
        complementedSectionKey = complementedSectionKey + '第一項';
      }
      regexp = new RegExp(`(${sectionNumber}|${complementedSectionKey})([(（].*[)）])?に規定する${linkText}`, 'ig');
    }

    const candidates = flattenCandidates(
      laws
        .map((law) => ({
          ...law,
          contents: law.contents ? law.contents.filter((c) => regexp.test(removeTags(c.content))) : [],
        }))
        .filter((law) => law.contents.length > 0),
    )
      .map<ContextMenuItem | undefined>(([law, link]) => {
        if (!law.contents) return undefined;
        return {
          label: law.title,
          subLabel: removeTags(law.contents.map((c) => c.content).join('/')),
          to: generateReferenceLocation(location.href, {
            reference_doc: law.id,
            reference_key: String(link.key),
            reference_series: String(link.series),
          }),
        };
      })
      .filter((law): law is ContextMenuItem => !!law);

    if (candidates.length === 0) {
      throw new Error('委任法令が見つかりませんでした');
    }

    return [{ header: '委任法令', items: candidates }];
  };
